/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Table,
  Text,
  TextInput,
} from '@mantine/core'
import {
  IconAlertTriangle,
  IconCalendar,
  IconCheck,
  IconExclamationCircle,
  IconEye,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  bulkUpsert,
  cancelJourneyLeg,
  getAllDataV3,
  getAllDataWithFilters,
  updateRecord
} from '../../services/common.service'
import SelectComp from '../../commonComponents/components/select/SelectComp'
import dayjs from 'dayjs'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import {
  checkOldJourneyErrors,
  clearJourneyStore,
  getFormattedJourneyDataForUpdate,
  useJourneyLegInventoryStore,
  useJourneyLegsStore,
  useJourneyStore,
  useSectorStore
} from '../../store/journeyStore'
import { getCabinUsers } from '../../services/employees.service'
import TimeInputComp from '../../commonComponents/components/dateInput/TimeInputComp'
import NumberInputComp from '../../commonComponents/components/numberInput/NumberInputComp'
import { notifications } from '@mantine/notifications'
import { COLORS } from '../../constants/colors'

const notificationIdJourney = 'edit-journey'
const notificationIdLeg = 'edit-journey-leg'
const notificationIdInventory = 'edit-journey-inventory'

const journeyStatus = {
  open: 'blue',
  closed: 'gray',
  cancelled: 'red',
  "partially open": 'blue',
  "auto cancelled": 'red.7'
}

const LegData = ({
  position,
  usersQuery,
  activeLeg,
  disabled,
  isEditable = false,
  airportsQuery,
  aircraftQueryData,
  onViewDetails,
  isJourneyStarted,
  journeyNumber,
}) => {
  const { legs, setLegsByIndex, legErrors, addNewLeg, removeLeg } =
    useJourneyLegsStore(state => state)
  const sectorState = useSectorStore(state => state)
  const index = position - 1
  const curLeg = legs?.[index]

  useEffect(() => {
    if (onViewDetails && legs.length > 0 && position === 1) {
      onViewDetails({
        ...legs[0],
        leg_number: 1
      })
    }
  }, [legs, position])
  console.log('legStatus', legs?.every(leg => ['cancelled', 'open'].includes(leg?.status)));


  if (!curLeg) {
    if (disabled || isJourneyStarted || !isEditable || !legs?.every(leg => ['cancelled', 'open'].includes(leg?.status))) return null
    if (legs.length + 1 === position) {
      return (
        <Table.Tr>
          <Table.Td colSpan={12}>
            <Flex justify={'center'}>
              <Button
                variant='light'
                onClick={addNewLeg}
                leftSection={<IconPlus size={14} />}
              >
                Add Leg
              </Button>
            </Flex>
          </Table.Td>
        </Table.Tr>
      )
    }
    return null
  }

  let disableEdit = disabled
  if (!disableEdit) {
    if (curLeg?.completed_by_scc || curLeg?.completed_by_cc) disableEdit = true
    if (!isEditable) disableEdit = true
    if (curLeg?.status && curLeg?.status !== 'open') disableEdit = true
  } else {
    if (!curLeg?.completed_by_scc && !curLeg?.completed_by_cc && (curLeg?.status === 'open' || !curLeg?.status)) {
      if (isEditable) disableEdit = false
    }
  }
  console.log('currentLeg', curLeg);

  const handleChange = (field, val) => {
    const item = legs?.[index]
    curLeg[field] = val
    setLegsByIndex(index, item)
  }

  const changeDestination = (val) => {
    const currentItem = legs?.[index]
    const nextItem = legs?.[index + 1]

    currentItem.arrive_airport_id = val
    setLegsByIndex(index, currentItem)

    if (nextItem) {
      nextItem.depart_airport_id = val
      setLegsByIndex(index + 1, nextItem)
    }

  }

  const toAirports = airportsQuery.data?.filter(
    item => item?.value !== curLeg?.depart_airport_id
  )

  return (
    <Table.Tr bg={activeLeg === position ? 'primary.1' : ''}>
      {/* <Table.Tr
      bg={
        status === 'cancelled'
          ? '#f88'
          : activeLeg === position
          ? 'primary.1'
          : ''
      }
    > */}
      <Table.Td>{position}</Table.Td>
      <Table.Td>
        <Center>{journeyNumber}</Center>
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={true}
          data={aircraftQueryData}
          placeholder={'Choose Aircraft'}
          variant={'filled'}
          value={curLeg?.aircraft_id}
          error={legErrors?.[index]?.aircraft_id}
          setValue={val => handleChange('aircraft_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'Flight no'}
          value={curLeg?.flight_number}
          error={legErrors?.[index]?.flight_number}
          setValue={e => handleChange('flight_number', e?.toUpperCase())}
        />
      </Table.Td>
      <Table.Td>
        <TimeInputComp
          isRequired
          showPicker
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          value={curLeg?.departure_time}
          error={legErrors?.[index]?.departure_time}
          setValue={e => {
            handleChange('departure_time', e?.target?.value)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={true}
          variant={'filled'}
          placeholder={'From'}
          data={airportsQuery.data}
          error={legErrors?.[index]?.depart_airport_id}
          value={curLeg?.depart_airport_id}
          setValue={(val, _option) => {
            handleChange('depart_airport_id', val)
            let s = {
              from: _option.airport_code
            }
            if (sectorState.sector[index]) {
              s.to = sectorState.sector[index]?.to
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'To'}
          data={toAirports}
          error={legErrors?.[index]?.arrive_airport_id}
          value={curLeg?.arrive_airport_id}
          setValue={(val, _option) => {
            changeDestination(val)
            let s = {
              to: _option.airport_code
            }
            if (sectorState.sector[index]) {
              s.from = sectorState.sector[index]?.from
            }
            sectorState.setSector(index, s)
          }}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'SCC'}
          data={usersQuery?.data?.filter(i => i?.value !== curLeg?.cabin_crew_id) || []}
          error={legErrors?.[index]?.senior_cabin_crew_id}
          value={curLeg?.senior_cabin_crew_id}
          setValue={val => handleChange('senior_cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          isRequired
          readOnly={disableEdit}
          variant={disableEdit ? 'filled' : 'default'}
          placeholder={'CC'}
          data={usersQuery?.data?.filter(i => i?.value !== curLeg?.senior_cabin_crew_id) || []}
          error={legErrors?.[index]?.cabin_crew_id}
          value={curLeg?.cabin_crew_id}
          setValue={val => handleChange('cabin_crew_id', val)}
        />
      </Table.Td>
      <Table.Td style={{ textAlign: 'center' }}>
        <Badge
          size='lg'
          variant='light'
          color={journeyStatus[curLeg?.status]}
          styles={{ label: { textTransform: 'capitalize' } }}
        >
          {curLeg?.status}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Flex direction={'row'} gap={'xs'} align={'center'}>
          <ActionIcon
            variant={'subtle'}
            // color={'primary'}
            onClick={() => {
              onViewDetails({
                ...curLeg,
                leg_number: position
              })
            }}
          >
            <IconEye size={16} />
          </ActionIcon>
          {position > 2 && position === legs.length && !curLeg?.id && !disableEdit ? (
            <ActionIcon
              variant={'subtle'}
              color={'red'}
              onClick={() => {
                removeLeg(index)
                sectorState.removeSector()
                onViewDetails({
                  ...legs[index - 1],
                  leg_number: position
                })
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          ) : (
            <ActionIcon variant={'transparent'} onClick={() => null} />
          )}
        </Flex>
      </Table.Td>
    </Table.Tr>
  )
}

const JourneyLegs = ({
  journeyId,
  legData,
  selectedLeg,
  onViewDetails,
  isEditable = false,
  disabled,
  isJourneyStarted,
  aircraftQueryData,
  journeyNumber
}) => {
  const setLegs = useJourneyLegsStore(state => state.setLegs)
  const airportsQuery = useQuery({
    queryKey: ['all-airports'],
    queryFn: () =>
      getAllDataV3({
        source: 'airports',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: items?.airport_code,
          value: `${items?.id}`
        }
      })
    }
  })
  const legsQuery = useQuery({
    queryKey: ['all-legs', journeyId],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg',
        page: -1,
        filter: { journey_id: journeyId },
        sort: [{ id: 'leg_number', desc: false }]
      })
  })

  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  useEffect(() => {
    if (legsQuery.data?.data?.length) {
      const result = legsQuery.data?.data?.map(item => {
        return {
          id: item.id,
          leg_number: item.leg_number,
          journey_number: item.journey_number,
          is_last_leg: item.is_last_leg,
          flight_number: item.flight_number,
          departure_time: item.departure_time,
          depart_airport_id: String(item.depart_airport_id?.id),
          arrive_airport_id: String(item.arrive_airport_id?.id),
          senior_cabin_crew_id: String(item.senior_cabin_crew_id?.id),
          cabin_crew_id: String(item.cabin_crew_id?.id),
          aircraft_id: String(item.aircraft_id?.id),
          completed_by_scc: item.completed_by_scc,
          completed_by_cc: item.completed_by_cc,
          cc_inventory_update_reason: item.cc_inventory_update_reason,
          scc_inventory_update_reason: item.scc_inventory_update_reason,
          status: item.status
        }
      })
      setLegs(result)
    }
  }, [legsQuery.data?.data])

  return (
    <Box>
      <Table.ScrollContainer>
        <Table verticalSpacing={8} horizontalSpacing={4}>
          <Table.Thead>
            <Table.Tr c='gray.6'>
              <Table.Td>#</Table.Td>
              <Table.Td align='center' w={'8%'}>
                Journey #
              </Table.Td>
              <Table.Td align='center' w={'8%'}>
                Aircraft
              </Table.Td>
              <Table.Td align='center' w={'10%'}>
                Flight No
              </Table.Td>
              <Table.Td align='center' w={'8%'}>
                Dep. Time
              </Table.Td>
              <Table.Td align='center' w={'8%'}>
                From
              </Table.Td>
              <Table.Td align='center' w={'8%'}>
                To
              </Table.Td>
              <Table.Td align='center' w={'18%'}>
                SCC
              </Table.Td>
              <Table.Td align='center' w={'16%'}>
                CC
              </Table.Td>
              <Table.Td align='center' w={'12%'}>
                Status
              </Table.Td>
              <Table.Td align='center'></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <LegData
              position={1}
              disabled={disabled}
              isJourneyStarted={isJourneyStarted}
              isEditable={isEditable}
              journeyDate={legData?.journey_date}
              activeLeg={selectedLeg?.leg_number || legData.leg_number}
              airportsQuery={airportsQuery}
              onViewDetails={onViewDetails}
              usersQuery={usersQuery}
              aircraftQueryData={aircraftQueryData}
              journeyNumber={journeyNumber}
              status={legsQuery.data?.data[0]?.status}
            />
            <LegData
              position={2}
              disabled={disabled}
              isJourneyStarted={isJourneyStarted}
              isEditable={isEditable}
              journeyDate={legData?.journey_date}
              activeLeg={selectedLeg?.leg_number || legData.leg_number}
              airportsQuery={airportsQuery}
              onViewDetails={onViewDetails}
              usersQuery={usersQuery}
              aircraftQueryData={aircraftQueryData}
              status={legsQuery.data?.data[1]?.status}
            />
            <LegData
              position={3}
              disabled={disabled}
              isJourneyStarted={isJourneyStarted}
              isEditable={isEditable}
              journeyDate={legData?.journey_date}
              activeLeg={selectedLeg?.leg_number || legData.leg_number}
              airportsQuery={airportsQuery}
              onViewDetails={onViewDetails}
              usersQuery={usersQuery}
              aircraftQueryData={aircraftQueryData}
              status={legsQuery.data?.data[2]?.status}
            />
            <LegData
              position={4}
              disabled={disabled}
              isJourneyStarted={isJourneyStarted}
              isEditable={isEditable}
              journeyDate={legData?.journey_date}
              activeLeg={selectedLeg?.leg_number || legData.leg_number}
              airportsQuery={airportsQuery}
              onViewDetails={onViewDetails}
              usersQuery={usersQuery}
              aircraftQueryData={aircraftQueryData}
              status={legsQuery.data?.data[3]?.status}
            />
            <LegData
              position={5}
              disabled={disabled}
              isJourneyStarted={isJourneyStarted}
              isEditable={isEditable}
              journeyDate={legData?.journey_date}
              activeLeg={selectedLeg?.leg_number || legData.leg_number}
              airportsQuery={airportsQuery}
              onViewDetails={onViewDetails}
              usersQuery={usersQuery}
              aircraftQueryData={aircraftQueryData}
              status={legsQuery.data?.data[4]?.status}
            />
          </Table.Tbody>

          {/* <Table.Tbody>
            {legsQuery.isSuccess && legsQuery.data?.data?.map((leg, index) => (
              <LegData
                key={leg.id}
                position={index + 1}
                disabled={disabled}
                isJourneyStarted={isJourneyStarted}
                journeyDate={leg.journey_date} // Ensure journey_date is taken from the current leg
                activeLeg={selectedLeg?.leg_number || leg.leg_number}
                airportsQuery={airportsQuery}
                onViewDetails={onViewDetails}
                usersQuery={usersQuery}
                aircraftQueryData={aircraftQueryData}
                journeyNumber={journeyNumber}
                status={leg.status} // Correctly passing the status from legsQuery
              />
            ))}
          </Table.Tbody> */}
        </Table>
      </Table.ScrollContainer>
    </Box>
  )
}

const ProductListing = ({
  id,
  legData,
  allLegData,
  selectedLeg,
  isEditable = false,
  disabled,
  journeyDate
}) => {
  const { inventory, inventoryError, setInventory, setInventoryItem } =
    useJourneyLegInventoryStore(state => state)
  const firstLeg = useJourneyLegsStore(state => state.legs[0])
  const [currentLegHasReasons, setCurrentLegHasReasons] = useState(false)
  const legProductsQuery = useQuery({
    queryKey: ['leg-products', selectedLeg?.id || id],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg_inventory',
        page: -1,
        filter: { journey_leg_id: selectedLeg?.id || id }
      })
  })

  const productsQuery = useQuery({
    enabled: legProductsQuery.status === 'success',
    queryKey: ['products-history'],
    queryFn: async () => {
      return getAllDataV3({
        source: 'products',
        page: -1,
        filter: { is_active: true },
        sort: [{ id: 'product_name', desc: false }]
      }).then(async (res) => {
        if (journeyDate && dayjs(journeyDate).isBefore(dayjs(dayjs().format('YYYY-MM-DD')))) {
          let finalResult = []
          let result = await getAllDataV3({
            source: 'product_history',
            page: -1,
            filter: { is_active: true, backup_date: `"${journeyDate}"` },
            sort: [{ id: 'product_name', desc: false }]
          }).then((res1) => {
            return res1?.data
          })
          if (result?.length) {
            finalResult = result?.map((i) => ({
              ...i,
              available_quantity: res?.data?.find(item => item?.id == i?.product_id)?.available_quantity,
              id: res?.data?.find(item => item?.id == i?.product_id)?.id,
            }))
          } else {
            finalResult = res?.data
          }
          return { ...res, data: finalResult }
        } else {
          return res
        }
      })
    }
  })

  useEffect(() => {
    if (productsQuery.data?.data) {
      const legProducts = legProductsQuery.data?.data
      const d = productsQuery.data?.data?.map(item => {
        const legPd = legProducts?.find(
          legProduct => legProduct?.product_id?.id == item?.id
        )

        console.log('legPd', legPd)

        const upliftCount =
          item?.new_default_uplift_count &&
            item?.new_default_uplift_count_effective_from &&
            dayjs(journeyDate).isSameOrAfter(
              dayjs(item?.new_default_uplift_count_effective_from)
            )
            ? item?.new_default_uplift_count
            : item?.default_uplift_count

        let result = {
          ...item,
          id: item?.id,
          old: legPd,
          // opening: (legPd?.opening_scc + legPd?.opening_cc) || 0,
          opening:
            (legPd?.opening_scc || 0) + (legPd?.opening_cc || 0) === 0
              ? 0
              : (legPd?.opening_scc || 0) +
              (legPd?.opening_cc || 0) -
              ((legPd?.opening_delta_by_scc || 0) + (legPd?.opening_delta_by_cc || 0)),
          closing: legPd?.closing_scc + legPd?.closing_cc || 0,
          delta_scc: legPd?.opening_delta_by_scc || '-',
          delta_cc: legPd?.opening_delta_by_cc || '-',
          uplift_count: upliftCount,
          cc_inventory_update_reason: legPd?.cc_inventory_update_reason || null,
          scc_inventory_update_reason:
            legPd?.scc_inventory_update_reason || null
        }

        // if (
        //   selectedLeg?.id && legProductsQuery.data?.data?.length &&
        //   allLegData?.find(i => i?.status !== 'cancelled')?.id ==
        //   selectedLeg?.id && allLegData?.[0]?.id != selectedLeg?.id
        // ) {
        //   result.opening = upliftCount
        // }

        delete result['product_id']

        return result
      })

      setInventory(d)

      const currentLegHasReasons =
        legProducts?.some(
          leg =>
            leg?.journey_id === selectedLeg?.journey_id &&
            ((leg?.scc_inventory_update_reason !== null &&
              leg?.scc_inventory_update_reason !== 'null') ||
              (leg?.cc_inventory_update_reason !== null &&
                leg?.cc_inventory_update_reason !== 'null'))
        ) ?? false // Default to `false` if `legProducts` is undefined

      setCurrentLegHasReasons(currentLegHasReasons)
    }
  }, [productsQuery.data, legProductsQuery.data, journeyDate])

  const handleChange = (e, item) => {
    item.opening = e
    setInventoryItem(item)
  }
  let disableEdit = disabled
  if (!disableEdit) {
    disableEdit = (selectedLeg?.leg_number || legData?.leg_number) !== 1;
  }

  if (disableEdit) {
    // eslint-disable-next-line no-extra-boolean-cast
    disableEdit = !Boolean(
      allLegData?.find(i => i?.status !== 'cancelled')?.id == selectedLeg?.id
    )
  }
  if (selectedLeg?.leg_number == 1 && selectedLeg?.status != 'open') disableEdit = true
  if (!isEditable) disableEdit = true

  return (
    <Box style={{ width: currentLegHasReasons ? '180%' : '100%' }}>
      {inventoryError ? (
        <Alert color={'red'} icon={<IconAlertTriangle size={16} />}>
          Kindly add opening for a product
        </Alert>
      ) : null}
      <Table>
        <Table.Thead bg={'primary.0'}>
          <Table.Tr c='gray.6' fw={600}>
            <Table.Td c={'primary'} fw={600}>
              Items (for Leg #{selectedLeg?.leg_number || legData?.leg_number})
            </Table.Td>
            <Table.Td align='right'>Available</Table.Td>
            <Table.Td align='center'>
              Uplifted
              <Box>
                <Text size='xs'>{firstLeg?.flight_number}</Text>
              </Box>
            </Table.Td>
            <Table.Td align='center'>Closing</Table.Td>
            {/* <Table.Td align='center'>Delta SCC</Table.Td>
            <Table.Td align='center'>Delta CC</Table.Td> */}

            {currentLegHasReasons && (
              <Table.Td align='center'>+/- by SCC</Table.Td>
            )}

            {currentLegHasReasons && (
              <Table.Td align='center'>Reason (SCC)</Table.Td>
            )}

            {currentLegHasReasons && (
              <Table.Td align='center'>+/- by CC</Table.Td>
            )}

            {currentLegHasReasons && (
              <Table.Td align='center'>Reason (CC)</Table.Td>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {inventory?.map((item) => (
            <Table.Tr key={item.product_name}>
              <Table.Td>{item.product_name}</Table.Td>
              <Table.Td align='right'>{item.available_quantity}</Table.Td>
              <Table.Td align='center'>
                <NumberInputComp
                  w={80}
                  isRequired
                  readOnly={disableEdit}
                  variant={disableEdit ? 'filled' : 'default'}
                  hideControls={disableEdit}
                  withKeyboardEvents
                  min={0}
                  max={item.available_quantity}
                  clampBehavior='strict'
                  value={item?.opening}
                  styles={{ input: { textAlign: 'center' } }}
                  setValue={e => handleChange(e, item)}
                />
              </Table.Td>
              <Table.Td align='center'>
                <NumberInputComp
                  w={80}
                  readOnly
                  variant='filled'
                  value={item?.closing}
                  setValue={() => null}
                  styles={{ input: { textAlign: 'center' } }}
                />
              </Table.Td>
              {currentLegHasReasons &&
                item?.scc_inventory_update_reason != null && (
                  <Table.Td align='center'>
                    <NumberInputComp
                      w={80}
                      readOnly
                      variant='filled'
                      value={item?.delta_scc}
                      setValue={() => null}
                      styles={{ input: { textAlign: 'center' } }}
                    />
                  </Table.Td>
                )}

              {currentLegHasReasons &&
                item?.scc_inventory_update_reason != null && (
                  <Table.Td align='center'>
                    <TextInputComp
                      w={80}
                      readOnly
                      variant='filled'
                      value={
                        item?.scc_inventory_update_reason == 'null'
                          ? ''
                          : item?.scc_inventory_update_reason
                      }
                      setValue={() => null}
                      styles={{ input: { textAlign: 'center' } }}
                    />
                  </Table.Td>
                )}

              {currentLegHasReasons &&
                item?.cc_inventory_update_reason != null && (
                  <Table.Td align='center'>
                    <NumberInputComp
                      w={80}
                      readOnly
                      variant='filled'
                      value={item?.delta_cc}
                      setValue={() => null}
                      styles={{ input: { textAlign: 'center' } }}
                    />
                  </Table.Td>
                )}

              {currentLegHasReasons &&
                item?.cc_inventory_update_reason != null && (
                  <Table.Td align='center'>
                    <TextInputComp
                      w={80}
                      readOnly
                      variant='filled'
                      setValue={() => null}
                      value={
                        item?.cc_inventory_update_reason == 'null'
                          ? ''
                          : item?.cc_inventory_update_reason
                      }
                      styles={{ input: { textAlign: 'center' } }}
                    />
                  </Table.Td>
                )}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>
  )
}

const EditJourneyForm = ({
  data,
  opened,
  size = '85%',
  onClose,
  onSuccess
}) => {
  const queryClient = useQueryClient()
  const [selectedLeg, setSelectedLeg] = useState()
  const journeyState = useJourneyStore(state => state)
  const firstLeg = useJourneyLegsStore(state => state.legs[0])
  const sectorPattern = useSectorStore(state => ({
    pattern: state.pattern,
    setPattern: state.setPattern,
    updateSector: state.updateSector,
    sectorState: state.setSector
  }))
  const [cancelData, setCancelData] = useState({
    modal: false,
    data: []
  })
  const [isEditable, setIsEditable] = useState(false)

  const productsQuery = useQuery({
    queryKey: ['all-products'],
    queryFn: () =>
      getAllDataV3({
        source: 'products',
        page: -1,
        filter: { is_active: true },
        sort: [{ id: 'product_name', desc: false }]
      })
  })

  // Open cancel modal
  const openCancelModal = () => {
    setCancelData({
      modal: true,
      data: legsQuery?.data?.data?.filter(i => i?.status == 'open')?.map(i => ({
        // eslint-disable-next-line no-extra-boolean-cast
        visible: !Boolean(i?.cancel_reason),
        legId: i?.id,
        leg_number: i?.leg_number,
        active: Boolean(i?.cancel_reason),
        reason: i?.cancel_reason || null
      }))
    })
  }

  // Close cancel modal
  const closeCancelModal = () => {
    setCancelData({ modal: false, legId: null, reason: null })
  }

  const legsQuery = useQuery({
    queryKey: ['all-legs', data?.journey_id?.id],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg',
        page: -1,
        filter: { journey_id: data?.journey_id?.id },
        sort: [{ id: 'leg_number', desc: false }]
      })
  })

  const allJourneyLegCancellationReasonsQuery = useQuery({
    queryKey: ['all-journey-leg-cancellation-reasons'],
    queryFn: () =>
      getAllDataWithFilters({
        source: 'reason_master',
        filter: [
          { key: 'module', value: 'Cancellation Reason', condition: 'eq' },
          { key: 'is_active', value: true, condition: 'eq' }
        ], // Correct format
        sort: [{ id: 'reason', desc: false }],
        page: -1
      }),
    select: data => {
      return data?.data?.map(item => ({
        ...item,
        label: item?.reason,
        value: item?.reason
      }))
    }
  })

  const handleSaveJourneyLegCancellationReason = async () => {
    if (!cancelData || !cancelData?.data?.length) {
      notifications.show({
        title: 'Error',
        message: 'Please select all the fields',
        color: 'red',
        icon: <IconExclamationCircle />,
        autoClose: 3000
      })
      return
    }

    const body = {
      cancel_legs: cancelData?.data
        ?.filter(i => i?.active && i?.reason)
        ?.map(i => ({
          leg_id: i?.legId,
          cancel_reason: i?.reason
        }))
    }

    await updateJourneyLegCancellationReasonMutation.mutateAsync({
      journeyId: data?.journey_id?.id,
      body
    })
  }

  const updateJourneyLegCancellationReasonMutation = useMutation({
    mutationFn: ({ journeyId, body }) => cancelJourneyLeg({ journeyId, body }),
    onSuccess: async () => {
      notifications.show({
        id: 'update-journey-leg-cancellation-reason',
        title: 'Journey Leg Cancellation Reason Updated',
        message: 'Successfully updated',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      closeCancelModal()

      await queryClient.invalidateQueries(['all-journeys', 'all-legs'])

      if (
        cancelData?.data?.find(i => i?.active && i?.visible)?.leg_number <=
        cancelData?.data?.find(i => !i?.active && i?.visible)?.leg_number &&
        cancelData?.data?.filter(i => !i?.active && i?.visible)?.length
      ) {
        let nextActiveLegInventory = await getAllDataV3({
          source: 'journey_leg_inventory',
          page: -1,
          filter: {
            journey_leg_id: cancelData?.data?.find(
              i => !i?.active && i?.visible
            )?.legId
          }
        })

        if (productsQuery.data?.data) {
          const legProducts = nextActiveLegInventory?.data
          const d = productsQuery.data?.data?.map(item => {
            const legPd = legProducts?.find(
              legProduct => legProduct?.product_id?.id === item?.id
            )

            const upliftCount =
              legPd?.new_default_uplift_count &&
                legPd?.new_default_uplift_count_effective_from &&
                dayjs(journeyState?.journey?.journey_date).isSameOrAfter(
                  dayjs(legPd?.new_default_uplift_count_effective_from)
                )
                ? item?.new_default_uplift_count
                : item?.default_uplift_count

            let result = {
              ...legPd,
              old: legPd,
              closing: legPd?.closing_scc + legPd?.closing_cc || 0,
              delta_scc: legPd?.opening_delta_by_scc || '-',
              delta_cc: legPd?.opening_delta_by_cc || '-',
              uplift_count: upliftCount,
              created_by: legPd?.created_by?.id,
              journey_leg_id: legPd?.journey_leg_id?.id,
              last_inv_updated_by_scc: dayjs().toDate(),
              last_inv_updated_by_cc: dayjs().toDate(),
              product_id: legPd?.product_id?.id,
              updated_by: legPd?.updated_by?.id,
              opening: upliftCount,
              opening_scc: Math.ceil(upliftCount / 2),
              opening_cc: Math.floor(upliftCount / 2)
            }

            return result
          })
          saveJourneyLegInventoryMutation.mutate(d, {
            onSuccess: () => {
              onSuccess()
              closeCancelModal()
            }
          })
        }
      }
    },
    onError: e => {
      notifications.show({
        id: 'update-journey-leg-cancellation-reason',
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    },
    onSettled: e => {
      onClose()
    }
  })

  useEffect(() => {
    if (data?.journey_id) {
      const j = data?.journey_id
      journeyState.setJourney({
        id: j?.id,
        journey_number: j?.journey_number,
        journey_date: j?.journey_date,
        journey_status: j?.journey_status,
        aircraft_id: String(j?.aircraft_id)
      })
      sectorPattern.setPattern(j?.sector_pattern)
    }
  }, [data])

  useEffect(() => {
    if (legsQuery?.data?.data) {
      legsQuery.data.data.forEach((i, index) => {
        sectorPattern.sectorState(index, { from: i?.depart_airport_id?.airport_code, to: i?.arrive_airport_id?.airport_code })
      })
    }
  }, [legsQuery?.data?.data])

  const aircraftQuery = useQuery({
    enabled: opened && Boolean(data?.id),
    queryKey: ['all-aircrafts'],
    queryFn: () =>
      getAllDataV3({
        source: 'aircrafts',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: items?.tail_reg_no,
          value: `${items?.id}`
        }
      })
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: body => updateRecord({ source: 'journey', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdJourney,
        title: 'Journey Details Updated',
        message: 'Please wait till we update leg info',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      notifications.update({
        id: notificationIdJourney,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveJourneyLegMutation = useMutation({
    mutationFn: body => bulkUpsert({ source: 'journey_leg', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdLeg,
        title: 'Journey Legs Updated',
        message: 'Successfully updated',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: notificationIdLeg,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveJourneyLegInventoryMutation = useMutation({
    mutationFn: body => bulkUpsert({ source: 'journey_leg_inventory', body }),
    onSuccess: () => {
      notifications.update({
        id: notificationIdInventory,
        title: 'Opening Items are Updated',
        message: 'Successfully updated',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: notificationIdInventory,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  if (!data) return null

  function handleSave() {
    if (
      !checkOldJourneyErrors({
        currentLeg: selectedLeg?.leg_number || data?.leg_number || 1
      })
    ) {
      const legNumber =
        legsQuery?.data?.data?.find(i => i?.status != 'cancelled')
          ?.leg_number == selectedLeg?.leg_number
          ? 1
          : selectedLeg?.leg_number
      const result = getFormattedJourneyDataForUpdate({
        currentLeg:
          legNumber || selectedLeg?.leg_number || data?.leg_number || 1
      })
      if (result.updateJourney) {
        const reqBody = result.journey
        notifications.show({
          id: notificationIdJourney,
          title: 'Updating Journey Details...',
          message: 'Please do not close anything.',
          loading: true,
          autoClose: false
        })
        saveJourneyMutation.mutate(
          {
            ...reqBody,
            sector_pattern: sectorPattern.pattern
          },
          {
            onSuccess: () => {
              if (result.updateLegs) {
                let reqBody = result.journey_leg
                console.log('reqBody', reqBody)
                
                if(reqBody?.length && reqBody?.filter(i => !i?.id).length) {
                  let newInventory = productsQuery.data?.data?.map((i) => ({
                    product_id: i?.id,
                  }))
                  let normalLegs = reqBody?.filter(i => i?.id)
                  let newLegs = reqBody?.filter(i => !i?.id)?.map((i) => ({
                    ...i,
                    journey_leg_inventory: newInventory
                  }))
                  reqBody = [...normalLegs, ...newLegs]
                }
                console.log('Updated reqBody', reqBody)
                notifications.show({
                  id: notificationIdLeg,
                  title: 'Updating Journey Legs...',
                  message: 'Please do not close anything.',
                  loading: true,
                  autoClose: false
                })
                saveJourneyLegMutation.mutate(reqBody)
              }
            },
            onSettled: () => {
              onSuccess()
            }
          }
        )
      } else if (!result.updateJourney && result.updateLegs) {
        if (result.updateLegs) {
          let reqBody = result.journey_leg
          console.log('reqBody', reqBody)
          
          if(reqBody?.length && reqBody?.filter(i => !i?.id).length) {
            let newInventory = productsQuery.data?.data?.map((i) => ({
              product_id: i?.id,
            }))
            let normalLegs = reqBody?.filter(i => i?.id)
            let newLegs = reqBody?.filter(i => !i?.id)?.map((i) => ({
              ...i,
              journey_leg_inventory: newInventory
            }))
            reqBody = [...normalLegs, ...newLegs]
          }
          console.log('Updated reqBody', reqBody)
          notifications.show({
            id: notificationIdLeg,
            title: 'Updating Journey Legs...',
            message: 'Please do not close anything.',
            loading: true,
            autoClose: false
          })
          saveJourneyLegMutation.mutate(reqBody, {
            onSuccess: () => {
              onSuccess()
            }
          })
        }
      }

      if (result.updateInventory) {
        const reqBody = result.journey_leg_inventory
        notifications.show({
          id: notificationIdInventory,
          title: 'Updating Opening Items...',
          message: 'Please do not close anything.',
          loading: true,
          autoClose: false
        })
        saveJourneyLegInventoryMutation.mutate(reqBody, {
          onSuccess: () => {
            onSuccess()
          }
        })
      }
    }
  }

  const handleClose = () => {
    clearJourneyStore()
    setSelectedLeg({})
    onClose()
  }

  let isPast = dayjs(new Date()).isAfter(
    dayjs(data?.journey_date + ' ' + data?.departure_time).toDate()
  )
  let isJourneyStarted = isPast
  if (!isPast) {
    isJourneyStarted = dayjs(new Date()).isAfter(
      dayjs(data?.journey_date + ' ' + firstLeg?.departure_time).toDate()
    )
  }

  return (
    <>
      <Drawer
        opened={opened}
        onClose={handleClose}
        title={
          <Group
            justify='space-between'
            w={'100%'}
            style={{ position: 'relative' }}
          >
            <Text size={'md'} fw={600}>
              Edit Journey
            </Text>
          </Group>
        }
        padding='md'
        size={size}
        position='right'
        radius='md'
        offset={8}
        overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
      >
        {legsQuery?.data?.data?.some(item => item.status == 'open') && (
          <Group
            gap={10}
            justify='flex-end'
            style={{ position: 'absolute', top: 16, right: 45, zIndex: 9999 }}
          >
            <Button
              size='xs'
              variant='outline'
              color='blue'
              disabled={!legsQuery?.data?.data?.every(item => ['open', 'cancelled', 'partially open'].includes(item.status))}
              onClick={() => setIsEditable(!isEditable)}
            >
              Edit Journey
            </Button>
            <Button
              size='xs'
              onClick={openCancelModal}
              variant='outline'
              color='red'
              bg={'red.0'}
            >
              Cancel Journey
            </Button>
          </Group>
        )}
        <Flex direction={'column'} gap={4}>
          <Box flex={1}>
            <Grid>
              <Grid.Col span={3}>
                <DateInputComp
                  isRequired
                  label={'Journey Date'}
                  // readOnly={isPast || isJourneyStarted || !isEditable}
                  // variant={isPast || isJourneyStarted || !isEditable ? 'filled' : 'default'}
                  readOnly={true}
                  variant={'filled'}
                  value={
                    journeyState?.journey?.journey_date
                      ? new Date(journeyState?.journey?.journey_date)
                      : null
                  }
                  rightSection={<IconCalendar size={16} />}
                  placeholder={'Select Journey Date'}
                  error={journeyState?.journeyErrors?.journey_date}
                  // minDate={new Date()}
                  // maxDate={dayjs(new Date()).add(15, 'day').toDate()}
                  setValue={e => {
                    journeyState.setJourney({
                      ...journeyState?.journey,
                      updateJourney: true,
                      journey_date: e ? dayjs(e).format('YYYY-MM-DD') : null
                    })
                  }}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <SelectComp
                  isRequired
                  label={'Aircraft'}
                  // readOnly={isPast || isJourneyStarted || !isEditable}
                  // variant={isPast || isJourneyStarted || !isEditable ? 'filled' : 'default'}
                  readOnly={true}
                  variant={'filled'}
                  placeholder={'Choose Aircraft'}
                  data={aircraftQuery.data}
                  value={journeyState?.journey?.aircraft_id}
                  error={journeyState?.journeyErrors.aircraft_id}
                  setValue={val => {
                    journeyState.setJourney({
                      ...journeyState?.journey,
                      updateJourney: true,
                      aircraft_id: val
                    })
                  }}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <TextInput
                  readOnly
                  variant='filled'
                  label={'Sector Pattern'}
                  value={sectorPattern.pattern}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                {journeyState?.journey?.id ? (
                  <JourneyLegs
                    id={data?.id}
                    legData={data}
                    disabled={isPast}
                    isEditable={isEditable}
                    isJourneyStarted={isJourneyStarted}
                    journeyId={journeyState?.journey?.id}
                    selectedLeg={selectedLeg}
                    aircraftQueryData={aircraftQuery.data || []}
                    onViewDetails={e => setSelectedLeg(e)}
                    journeyNumber={data?.journey_number}
                  />
                ) : null}
              </Grid.Col>
              <Grid.Col span={6}>
                {journeyState?.journey?.id ? (
                  <ProductListing
                    id={data?.id}
                    disabled={isPast}
                    isEditable={isEditable}
                    legData={data}
                    isJourneyStarted={isJourneyStarted}
                    journeyId={journeyState?.journey?.id}
                    journeyDate={journeyState?.journey?.journey_date}
                    selectedLeg={selectedLeg}
                    allLegData={legsQuery?.data?.data}
                  />
                ) : null}
              </Grid.Col>
            </Grid>
          </Box>
          <Grid hidden={!isEditable}>
            <Grid.Col span={12}>
              <Group justify='flex-start' mt={'md'}>
                <Group>
                  <Button
                    w={200}
                    leftSection={<IconCheck size={16} />}
                    onClick={handleSave}
                    loading={saveJourneyMutation.isLoading}
                  >
                    Save
                  </Button>
                  <Button
                    w={200}
                    color='gray'
                    variant='light'
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Group>
              </Group>
            </Grid.Col>
          </Grid>
        </Flex>
      </Drawer>

      <Modal
        opened={cancelData.modal}
        onClose={closeCancelModal}
        size='lg'
        radius='md'
        withCloseButton={false}
      >
        <Text size='md'>
          You are about to cancel your journey. Please select a reason and
          confirm to proceed.
        </Text>
        <Grid>
          <Grid.Col span={4}>
            <DateInputComp
              label={'Journey Date'}
              readOnly={true}
              variant={'filled'}
              value={
                journeyState?.journey?.journey_date
                  ? new Date(journeyState?.journey?.journey_date)
                  : null
              }
              rightSection={<IconCalendar size={16} />}
              placeholder={'Select Journey Date'}
              error={journeyState?.journeyErrors?.journey_date}
              setValue={e => {
                journeyState.setJourney({
                  ...journeyState?.journey,
                  updateJourney: true,
                  journey_date: e ? dayjs(e).format('YYYY-MM-DD') : null
                })
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInputComp
              readOnly={true}
              variant={'filled'}
              label={'Flight no'}
              value={
                journeyState?.journey?.aircraft_id
                  ? aircraftQuery?.data?.filter(
                    i => i?.value == journeyState?.journey?.aircraft_id
                  )[0]?.label
                  : ''
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInputComp
              readOnly={true}
              label={'Sector'}
              variant={'filled'}
              placeholder={'Sector'}
              value={sectorPattern?.pattern}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={2}></Grid.Col>
          <Grid.Col span={3}>
            <Text>From</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text>To</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text>Reason</Text>
          </Grid.Col>
          {legsQuery?.data?.data?.length
            ? legsQuery?.data?.data?.filter(i => i?.status == 'open')?.map(i => (
              <>
                <Grid.Col span={2}>
                  <Checkbox
                    color='blue'
                    mt={'xs'}
                    ml={'lg'}
                    readOnly={
                      !cancelData?.data?.find(it => it?.legId == i?.id)
                        ?.visible
                    }
                    checked={
                      cancelData?.data?.find(it => it?.legId == i?.id)?.active
                    }
                    onChange={e => {
                      if (
                        !cancelData?.data?.find(it => it?.legId == i?.id)
                          ?.visible
                      ) {
                        return null
                      }
                      let data = cancelData?.data?.find(
                        it => it?.legId == i?.id
                      )
                      data.active = e.currentTarget.checked
                      let result = cancelData?.data
                      result.splice(
                        cancelData?.data?.findIndex(it => it?.legId == i?.id),
                        1,
                        data
                      )
                      setCancelData({ modal: true, data: result })
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <TextInputComp
                    readOnly={true}
                    variant={'filled'}
                    placeholder={'Sector'}
                    value={i?.depart_airport_id?.airport_code}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <TextInputComp
                    readOnly={true}
                    variant={'filled'}
                    placeholder={'Sector'}
                    value={i?.arrive_airport_id?.airport_code}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectComp
                    options={
                      allJourneyLegCancellationReasonsQuery?.data || []
                    }
                    readOnly={
                      !cancelData?.data?.find(it => it?.legId == i?.id)
                        ?.visible
                    }
                    value={
                      cancelData?.data?.find(it => it?.legId == i?.id)?.reason
                    }
                    onChange={e => {
                      let data = cancelData?.data?.find(
                        it => it?.legId == i?.id
                      )
                      data.reason = e
                      let result = cancelData?.data
                      result.splice(
                        cancelData?.data?.findIndex(it => it?.legId == i?.id),
                        1,
                        data
                      )
                      setCancelData({ modal: true, data: result })
                    }}
                  />
                </Grid.Col>
              </>
            ))
            : null}
        </Grid>
        <Group justify='flex-end' gap={10} mt='xl'>
          <Button
            size='xs'
            variant='light'
            color='gray'
            onClick={closeCancelModal}
          >
            Cancel
          </Button>
          <Button
            size='xs'
            color={COLORS.primary}
            onClick={handleSaveJourneyLegCancellationReason}
            loading={updateJourneyLegCancellationReasonMutation.isPending}
            disabled={
              !cancelData.data?.filter(i => i?.active && i?.reason)?.length
            }
          >
            Confirm
          </Button>
        </Group>
      </Modal>
    </>
  )
}

export default EditJourneyForm

/* eslint-disable react/prop-types */
import { ActionIcon, Box, TextInput } from "@mantine/core";
import { COLORS } from "../../../constants/colors";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";

const TextInputDisabledWithDeleteComp = ({
  value,
  setValue,
  placeholder,
  label,
  error,
  isRequired = false,
  isEditable,
  setIsEditable,
  mainObj,
  setIsDisabled,
  isDisabled,
  ...props
}) => {

  const handleChange = (e) => {
    const newValue = e.target.value.trim() === "" ? null : Number(e.target.value);
    setValue({ [mainObj?.field]: newValue });
  };
  
  return (
    <Box>
      <TextInput
        placeholder={placeholder}
        label={label}
        type="number"
        value={value}
        withAsterisk={isRequired}
        error={error}
        onChange={handleChange}
        rightSection={
          props.disabled && (
            <ActionIcon
              color="red"
              variant="transparent"
              onClick={() => {
                if (JSON.parse(props?.special || "[]")?.reset_column?.length) {
                  let objVal = {};
                  let objValEdit = {};
                  JSON.parse(props?.special || "[]")?.reset_column?.map(
                    (item) => {
                      objVal[item] = null;
                      objValEdit[item] = true;
                    }
                  );
                  setValue({ ...objVal, [mainObj?.field]: null });
                  setIsDisabled({ ...isDisabled, ...objValEdit });
                  setIsEditable({...isEditable, [mainObj?.field]: false});
                }
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          )
        }
        {...props}
        // disabled={isEditable}
      />
    </Box>
  );
};

export default TextInputDisabledWithDeleteComp;

import {
  ActionIcon,
  AppShell,
  Burger,
  Group,
  ScrollArea,
  Tooltip,
  UnstyledButton,
  Flex,
  Text,
  Menu,
  Image,
  Badge
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { COLORS } from '../../constants/colors'
import {
  IconCategory2,
  IconChartDonut2,
  IconChevronDown,
  IconDatabaseCog,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconListCheck,
  IconLogout2,
  IconMapPinShare,
  IconPackageImport,
  IconReceipt,
  IconReceiptRefund,
  IconReportAnalytics,
  IconUser,
  IconUserSquareRounded
} from '@tabler/icons-react'
import useAuthStore from '../../store/authStore'
import { postAPI } from './../../services/apiCall/postAPI'
import { useMutation } from '@tanstack/react-query'

const ListItem = ({ expand, label, onClick, iconComp, active }) => {
  return (
    <Tooltip
      hidden={expand}
      label={label}
      withArrow
      position='right'
      offset={14}
    >
      <UnstyledButton
        dir='ltr'
        onClick={onClick}
        bg={active ? 'primary.1' : 'white'}
        w={'100%'}
        style={{ borderRadius: 4 }}
      >
        <Group justify='flex-start' gap={'xs'}>
          <Flex align={'center'} dir='row'>
            <ActionIcon variant={'transparent'} size={50}>
              {iconComp}
            </ActionIcon>
            <Text
              size='md'
              hidden={!expand}
              c={active ? 'primary.9' : 'dark.3'}
            >
              {label}
            </Text>
          </Flex>
        </Group>
      </UnstyledButton>
    </Tooltip>
  )
}

const iconProps = {
  strokeWidth: 1.5,
  color: COLORS.primary
}

const links = [
  {
    label: 'Dashboard',
    iconComp: <IconChartDonut2 {...iconProps} />,
    link: '/',
    path: ''
  },
  {
    label: 'Masters',
    iconComp: <IconDatabaseCog {...iconProps} />,
    link: '/masters/aircraft',
    path: 'masters'
  },
  {
    label: 'Products',
    iconComp: <IconCategory2 {...iconProps} />,
    link: '/products',
    path: 'products'
  },
  {
    label: 'Journeys',
    iconComp: <IconMapPinShare {...iconProps} />,
    link: '/journeys',
    path: 'journeys'
  },
  // {
  //   label: 'Orders',
  //   iconComp: <IconListCheck {...iconProps} />,
  //   link: '/orders',
  //   path: 'orders'
  // },
  {
    label: 'Retrievals',
    iconComp: <IconPackageImport {...iconProps} />,
    link: '/retrievals',
    path: 'retrievals'
  },
  {
    label: 'GRNs',
    iconComp: <IconReceipt {...iconProps} />,
    link: '/grn',
    path: 'grn'
  },
  {
    label: 'Returns',
    iconComp: <IconReceiptRefund {...iconProps} />,
    link: '/return-product',
    path: 'return-product'
  },
  {
    label: 'Reports',
    iconComp: <IconReportAnalytics {...iconProps} />,
    link: '/reports',
    path: 'reports'
  }
]

const AppShellLayout = () => {
  const [opened, { toggle }] = useDisclosure()
  const [expand, { toggle: toggleExpand }] = useDisclosure()
  const { auth, resetAuth } = useAuthStore()
  const navigate = useNavigate()
  const location = useLocation()

  const onLogoutQuery = useMutation({
    mutationFn: async () => {
      await postAPI('/logout')
    },
    onSettled: () => {
      resetAuth()
    }
  })

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: expand ? 200 : 72,
        breakpoint: 'sm',
        collapsed: { mobile: !opened }
      }}
      padding='md'
    >
      <AppShell.Header>
        <Group h='100%' px='md' justify='space-between'>
          <Burger opened={opened} onClick={toggle} hiddenFrom='sm' size='sm' />
          <Group>
            <Image
              src='/images/logo.png'
              height={24}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            {/* <Text size='md' c='gray.6'>IFS</Text> */}
            <Badge color='primary' variant='outline'>
              IFS
            </Badge>
          </Group>
          <Group gap={30}>
            {/* <IconBellRinging color='gray' style={{ cursor: 'pointer' }} /> */}
            <Menu withArrow shadow='xl' width={150}>
              <Menu.Target>
                <Group gap={10} style={{ cursor: 'pointer' }}>
                  <Text c={'gray'}>{auth?.emp_name}</Text>
                  <Group gap={4}>
                    <IconUserSquareRounded color='gray' stroke={1.5} />
                    <IconChevronDown size={16} color='gray' />
                  </Group>
                </Group>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<IconUser size={14} />}
                  onClick={() => navigate('/profile')}
                >
                  View Profile
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconLogout2 size={14} />}
                  onClick={onLogoutQuery.mutate}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar>
        {/* Navbar List Items Start */}
        <AppShell.Section
          grow
          my='md'
          component={ScrollArea}
          ta={expand ? 'left' : 'center'}
          p={'xs'}
        >
          {links.map(link => {
            const active = location.pathname?.split('/')?.[1]
            return (
              <ListItem
                expand={expand}
                key={link.label}
                label={link.label}
                iconComp={link.iconComp}
                active={link.path === active}
                onClick={() => navigate(link.link)}
              />
            )
          })}
          {/* <ListItem
            expand={expand}
            label={'Dashboard'}
            iconComp={<IconChartDonut2 {...iconProps} />}
            active
            onClick={() => navigate('/')}
          />
          <ListItem
            expand={expand}
            label={'Masters'}
            iconComp={<IconDatabaseCog {...iconProps} />}
            active
            onClick={() => navigate('/masters/aircraft')}
          /> */}
        </AppShell.Section>
        {/* Navbar List Items End */}

        {/* Navbar Footer Start */}
        <AppShell.Section ta={expand ? 'left' : 'center'} p={'xs'}>
          <ListItem
            expand={expand}
            label={expand ? 'Collapse' : 'Expand'}
            iconComp={
              expand ? (
                <IconLayoutSidebarLeftCollapse {...iconProps} />
              ) : (
                <IconLayoutSidebarLeftExpand {...iconProps} />
              )
            }
            onClick={toggleExpand}
          />
          <ListItem
            expand={expand}
            label={'Logout'}
            iconComp={<IconLogout2 {...iconProps} />}
            onClick={onLogoutQuery.mutate}
          />
        </AppShell.Section>
        {/* Navbar Footer End */}
      </AppShell.Navbar>

      <AppShell.Main style={{ alignItems: 'center' }}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )
}

export default AppShellLayout

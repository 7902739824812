/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { getFormBuilderData } from "../../../services/master.service";
import { IconCheck, IconTrash } from "@tabler/icons-react";
import ModalComp from "../modal/ModalComp";
import { Button, Grid, Group, ScrollArea } from "@mantine/core";
import { FormBuilderFields } from "../../formBuilder/FormBuilderFields";
import useAuthStore from "../../../store/authStore";
import { displayNotification } from "../../notifications/displayNotification";

const EditModal = ({
  saveLoading,
  tableName,
  onSave,
  builderTitle,
  opened,
  size = "xl",
  onClose,
  formData,
}) => {
  const { auth } = useAuthStore();
  const [checkError, setCheckError] = useState();
  const [formBuilder, setFormBuilder] = useState({
    edit: true,
    data: formData,
  });

  const [isDisabled, setIsDisabled] = useState({});
  const [isEditable, setIsEditable] = useState({});

  const getDataHeaderQuery = useQuery({
    enabled: opened,
    queryKey: ["formBuilders-fields", tableName],
    queryFn: () => getFormBuilderData({ tableName }),
    select: (data) => {
      return data?.[0];
    },
  });

  const [originalData, setOriginalData] = useState({}); 

  useEffect(() => {
    if (formData && getDataHeaderQuery?.data?.length) {
      setDataFields();
      setOriginalData(formData); // Store initial data
    }
  }, [formData, getDataHeaderQuery?.data]);


  // const setDataFields = () => {
  //   let result = getDataHeaderQuery?.data
  //     // ?.filter(item => !item?.hidden)
  //     ?.map((item) => ({
  //       [item?.field]: item?.display_options
  //         ? formData?.[item?.field]?.[item?.return_value]
  //         : formData?.[item?.field],
  //     }));

  //   const output = result.reduce((acc, obj) => ({ ...acc, ...obj }), {});
  //   let disabledHeaders = {};
  //   getDataHeaderQuery?.data?.map((item) => {
  //     if (JSON.parse(item?.special)?.reset_column?.length) {
  //       disabledHeaders[item?.field] = output?.[item?.field] ? true : false;
  //     }
  //   });
  //   console.log("disabledHeaders", disabledHeaders);
  //   setIsEditable(disabledHeaders);
  //   setFormBuilder((old) => ({ ...old, data: output }));
  // };

  const setDataFields = () => {
    let result = getDataHeaderQuery?.data?.map((item) => ({
      [item?.field]: item?.display_options
        ? formData?.[item?.field]?.[item?.return_value]
        : formData?.[item?.field],
    }));
  
    const output = result.reduce((acc, obj) => ({ ...acc, ...obj }), {});
    let disabledHeaders = {};
  
    getDataHeaderQuery?.data?.forEach((item) => {
      let specialData = JSON.parse(item?.special || "{}");

      if ((item?.field || "").toLowerCase() === "opening_quantity") {
        disabledHeaders[item?.field] = true;
      }

      if ((item?.field || "").toLowerCase() === "available_quantity") {
        disabledHeaders[item?.field] = true;
      }

      // Ensure the main field is added
      if (specialData?.reset_column?.length) {
        disabledHeaders[item?.field] = output?.[item?.field] ? true : false;
        
        // Ensure reset_column fields are also included
        specialData.reset_column.forEach((resetField) => {
          disabledHeaders[resetField] = output?.[resetField] ? true : false;
        });
      }
    });
  
    setIsEditable(disabledHeaders);
    setFormBuilder((old) => ({ ...old, data: output }));
  };
  
  useEffect(() => {
    if (formData && getDataHeaderQuery?.data?.length) {
      // setFormBuilder({})
      setDataFields();
    }
  }, [formData, getDataHeaderQuery?.data]);

  useEffect(() => {
    if (opened == false) {
      setFormBuilder({});
    }
  }, [opened]);

  function handleSave() {
    const changedData = Object.keys(formBuilder.data).reduce((acc, key) => {
      if (formBuilder.data[key] !== originalData[key]) {
        acc[key] = formBuilder.data[key];
        acc["id"] = originalData["id"];
      }
      return acc;
    }, {});
  
    if (Object.keys(changedData).length > 0) {
      onSave(changedData);
    } else {
      displayNotification("No changes detected", "info");
    }
    setIsDisabled({});
  }

  return (
    <ModalComp
      opened={opened}
      closeOnClickOutside={false}
      onClose={() => {
        onClose();
        setCheckError();
        setIsDisabled({});
      }}
      size={size}
      radius={"md"}
      title={builderTitle}
    >
      <ScrollArea.Autosize
        mah={"70vh"}
        mih={120}
        // scrollbars='y'
        // type='always'
        offsetScrollbars
      >
        <Grid>
          {getDataHeaderQuery?.data
            ?.filter((item) => {
              const isVisible =
                !item?.hidden && item?.field !== "plain_password";
              const isSuperAdmin =
                auth?.emp_role_id?.role_name?.toUpperCase() === "SUPER ADMIN" &&
                item?.field === "plain_password";

              return isVisible || isSuperAdmin;
            })
            ?.map((item, index) => (
              <Grid.Col key={index} span={6}>
                <FormBuilderFields
                  item={item}
                  key={index}
                  isDisabled={isDisabled}
                  isEditable={isEditable}
                  setIsDisabled={setIsDisabled}
                  setIsEditable={setIsEditable}
                  value={formBuilder?.data}
                  setValue={setFormBuilder}
                  checkError={checkError}
                />
              </Grid.Col>
            ))}
        </Grid>
      </ScrollArea.Autosize>
      <Grid>
        <Grid.Col span={12}>
          <Group justify="flex-end" mt={"md"}>
            <Group>
              {/* <Button
                variant='light'
                color={'red'}
                leftSection={<IconTrash strokeWidth={1.5} size={16} />}
                onClick={() => {
                  
                }}
              >
                Delete
              </Button> */}
              <Button
                variant="light"
                color="gray"
                onClick={() => {
                  setFormBuilder({ edit: false });
                  setDataFields();
                  setCheckError();
                  setIsDisabled();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                leftSection={<IconCheck size={16} />}
                onClick={() => handleSave(formBuilder.data, setFormBuilder)}
                loading={saveLoading}
              >
                Save
              </Button>
            </Group>
          </Group>
        </Grid.Col>
      </Grid>
    </ModalComp>
  );
};

export default EditModal;

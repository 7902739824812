import { Table, Box, Group, Tooltip, ActionIcon, Button, Grid, Popover, Center, Loader } from "@mantine/core";
import Body from "../../commonComponents/body/Body";
import Header from "../../commonComponents/layout/Header";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDashBoardData } from "../../services/common.service";
import { useEffect, useMemo, useRef, useState } from "react";
import { DateInput, DatePicker, DatePickerInput, MonthPickerInput } from "@mantine/dates";
import { IconArrowRight, IconDownload, IconFilter } from "@tabler/icons-react";
import { COLORS } from "../../constants/colors";
import { displayNotification } from "../../commonComponents/notifications/displayNotification";
import ModalComp from "../../commonComponents/components/modal/ModalComp";
import { FormBuilderFields } from "../../commonComponents/formBuilder/FormBuilderFields";

const source = "sales-summary";

const Dashboard = () => {
  dayjs.extend(calendar);

  const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(dayjs().endOf("month").format("YYYY-MM-DD"))
  const [filter, setFilter] = useState({ modal: false, data: {} })
  const [pagination, setPagination] = useState({})
  const firstColRef = useRef(null);

  const data = useQuery({
    queryKey: ["dashboard-data", source, startDate, endDate, pagination],
    queryFn: () => getDashBoardData({ source, startDate, endDate, ...pagination })
  });

  const exportData = useMutation({
    mutationKey: [`export-report-${source}`],
    mutationFn: body => getDashBoardData({ source, startDate, endDate, ...pagination, fileType: true }),
    onSuccess: res => {
      if (res?.data?.[0]?.url) {
        window.open(res?.data?.[0]?.url, '_blank')
      } else {
        displayNotification({
          message: 'File Download Failed',
          variant: 'error'
        })
      }
    },
    onError: err => {
      displayNotification({
        message: err?.message || err,
        variant: 'error'
      })
    }
  })

  const tableData = data?.data?.data || []
  const tableHeader = data?.data?.metadata[0]?.headers || []
  const tableFilters = data?.data?.metadata[0]?.filters || []

  const mergedData = useMemo(() => {
    return tableData.map((row) => {
      const rowData = tableHeader.reduce((acc, item) => {
        acc[item.field] = row[item.field] || (row.hidden_header ? 0 : "-")
        return acc
      }, {});
      rowData.hidden_header = row?.hidden_header || null
      rowData.hidden_tooltip = row?.hidden_tooltip || null
      return rowData
    });
  }, [tableData, tableHeader])

  const handleClear = () => {
    setPagination({
      ...pagination,
      filter: {}
    })
    setFilter({ modal: false })
  }

  const handleFilterSubmit = () => {
    if (filter?.data !== undefined
      && filter?.data !== null
      && Object.entries(filter?.data)?.length) {
      setPagination({
        ...pagination,
        filter: filter?.data
      })
      setFilter({ ...filter, modal: false })
    } else {
      displayNotification({
        message: 'Please add filters',
        variant: 'warning'
      })
    }
  }

  const formatInLakhs = (value) => {
    if (!value || isNaN(value)) return value; // Handle empty or invalid values
    return (value / 100000).toFixed(2); // Convert to lakhs and round to 2 decimal places
  };

  useEffect(() => {
    if (firstColRef.current) {
      const firstColWidth = firstColRef.current.offsetWidth;
      document.documentElement.style.setProperty("--col0-width", `${firstColWidth}px`);
    }
  }, [tableData]); // Run this whenever table data changes
    
  return (
    <>
      <Group justify='space-between'>
        <Group gap={10}>
          <Header title={"Dashboard"} />
          <Group justify='center' gap={10}>
            <DatePickerInput
              size='xs'
              label="From Date"
              value={dayjs(startDate)}
              onChange={(e) => {
                setStartDate(dayjs(e))
              }}
              maxDate={dayjs(endDate)}
              w={150}
            />
            <IconArrowRight size={18} strokeWidth={1.5} style={{ marginTop: "16px" }} />
            <DatePickerInput
              size='xs'
              label="To Date"
              value={dayjs(endDate)}
              onChange={(e) => {
                setEndDate(dayjs(e))
              }}
              minDate={dayjs(startDate)}
              maxDate={dayjs(new Date())}
              w={150}
            />
          </Group>
        </Group>
        <Group gap={10}>
          {tableFilters?.length && (
            <Tooltip
              withArrow
              position='top'
              label={'Open Filter'}
            >
              <ActionIcon
                variant='outline'
                color={COLORS.green}
                size={'30'}
                onClick={() => setFilter({ ...filter, modal: true })}
              >
                <IconFilter strokeWidth={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
          <Button
            size='xs'
            variant='outline'
            color={COLORS.green}
            rightSection={<IconDownload size={16} />}
            onClick={() => exportData?.mutate()}
          >
            Download
          </Button>
        </Group>
      </Group>
      <Body>
        {data?.isLoading ? (
          <Center style={{ height: "50vh" }}>
            <Loader size="sm" color={COLORS?.primary} />
          </Center>
        ) : (
          <Box mt={'lg'} style={{ overflow: 'hidden' }}>
          <Table.ScrollContainer style={{ 
            position: 'relative'
          }}>
            <Table
              horizontalSpacing='lg'
              style={{
                width: "100%",
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                borderCollapse: 'separate' // Needed for sticky to work properly
              }}
            >
              <Table.Thead
                mt={5}
                c={'gray.7'}
                style={{
                  background: 'rgb(228, 237, 253)',
                  position: 'sticky',
                  top: 0,
                  zIndex: 2
                }}
              >
                <Table.Tr>
                  {tableHeader?.map((header, index) => (
                    <Table.Th
                      key={index}
                      ref={index === 0 ? firstColRef : null}
                      style={{
                        textAlign: 'left',
                        position: index <= 1 ? 'sticky' : 'static', // Sticky for first two columns
                        left: index === 0 ? 0 : index === 1 ? 'var(--col0-width, auto)' : 'auto',
                        zIndex: index <= 1 ? 4 - index : 'auto', // Higher z-index for first column
                        background: 'rgb(228, 237, 253)',
                      }}
                    >
                      {header?.label}
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {(() => {
                  const rowsWithTooltip = mergedData
                    ?.filter(row => row.hidden_tooltip !== null && !row.hidden_header)
                    ?.sort((a, b) => (b.total || 0) - (a.total || 0));

                  const finalData = mergedData?.map(row =>
                    row.hidden_tooltip !== null && !row.hidden_header
                      ? rowsWithTooltip.shift()
                      : row
                  );

                  return finalData?.map((row, index) => (
                    <Table.Tr
                      key={index}
                      style={{
                        backgroundColor: row.hidden_header ? 'rgb(228, 237, 253)' : 'rgb(256, 256, 256)',
                        position: 'relative'
                      }}
                    >
                      {tableHeader?.map((item, i) => {
                        const actualValue = row?.[item?.field];
                        const isNumber = !isNaN(actualValue) && actualValue !== null;
                        const shouldFormat = row?.hidden_tooltip !== null && isNumber;
                        const displayValue = shouldFormat ? formatInLakhs(actualValue) : actualValue;

                        return (
                          <Table.Td 
                            key={i}
                            style={{
                              position: i <= 1 ? 'sticky' : 'static',
                              left: i === 0 ? 0 : i === 1 ? 'var(--col0-width, auto)' : 'auto',
                              zIndex: i <= 1 ? 2 - i : 'auto',
                              backgroundColor: row.hidden_header 
                                ? 'rgb(228, 237, 253)' 
                                : i <= 1 
                                  ? 'rgb(256, 256, 256)'
                                  : 'transparent',
                              whiteSpace: 'nowrap',
                              // minWidth: i === 0 ? 200 : i === 1 ? 150 : 'auto'
                            }}
                          >
                            <Tooltip label={actualValue} withArrow position="right-end" disabled={!shouldFormat || actualValue == 0}>
                              <span>{actualValue === '-' ? 0 : displayValue}</span>
                            </Tooltip>
                          </Table.Td>
                        );
                      })}
                    </Table.Tr>
                  ));
                })()}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Box>
        )}
      </Body>
      <ModalComp
        opened={filter?.modal}
        radius={'md'}
        size={'xl'}
        title={'Filters'}
        onClose={() => setFilter({ modal: false })}
      >
        <Grid>
          {tableFilters?.map((item, index) => (
            <Grid.Col span={6} key={index}>
              <FormBuilderFields
                key={index}
                value={filter?.data}
                setValue={setFilter}
                item={item}
              />
            </Grid.Col>
          ))}
        </Grid>
        <Group justify='flex-end' gap={10} mt={'xl'}>
          <Button
            variant='outline'
            color='blue'
            onClick={handleClear}
          >
            Reset
          </Button>
          <Button
            color={COLORS.primary}
            onClick={handleFilterSubmit}
          >
            Submit
          </Button>
        </Group>
      </ModalComp>
    </>
  );
};

export default Dashboard;

import {
  ActionIcon,
  Box,
  Button,
  Group,
  Skeleton,
  Table,
  Text
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconAlertCircle, IconPlus, IconX } from '@tabler/icons-react'
import SelectComp from '../../../commonComponents/components/select/SelectComp'
import TextInputComp from '../../../commonComponents/components/textInput/TextInputComp'
import { memo } from 'react'
import { displayNotification } from '../../../commonComponents/notifications/displayNotification'

const consumed_by = ['A', 'C', 'D', 'F']
  ?.map(item => {
    return Array(18)
      .fill(0)
      ?.map((_, index) => {
        return `${index + 1}${item}`
      })
  })
  .flat()
const CustomerSalesBody = ({
  allProducts,
  allPaymentReason,
  setCustomerSalesData,
  legProductsQuery,
  customerSalesData,
  isReadOnly,
  error,
  item,
  index
}) => {
  const handleChange = (field, val) => {
    let data = customerSalesData
    data.splice(index, 1, {
      ...item,
      [field]: val
    })
    setCustomerSalesData(data)
  }

  const getMaxProductQuantity = productId => {
    if (legProductsQuery?.data?.length) {
      const product = legProductsQuery?.data?.find(
        item => item?.product_id?.id == productId
      )

      const sold_quantity = customerSalesData
        ?.filter(
          (i, iIndex) => i?.product_id?.id == productId && iIndex != index
        )
        ?.reduce(
          (acc, curr) => ({
            CC:
              curr?.journey_order_id?.sold_by == 'CC'
                ? acc.CC + curr.quantity
                : acc.CC,
            SCC:
              curr?.journey_order_id?.sold_by == 'SCC'
                ? acc.SCC + curr.quantity
                : acc.SCC
          }),
          { CC: 0, SCC: 0 }
        )
      return {
        CC: product?.opening_cc - (sold_quantity?.CC || 0) || 0,
        SCC: product?.opening_scc - (sold_quantity?.SCC || 0) || 0
      }
    }
  }

  return (
    <Table.Tr
      onClick={() => {
        if (isReadOnly) {
          notifications.show({
            title: 'Warning',
            message:
              'There are some leg changes not saved. Please save it or reset it.',
            color: 'red',
            icon: <IconAlertCircle />
          })
        }
      }}
      style={{ background: item?.quantity == 0 ? '#f88' : '' }}
    >
      <Table.Td>{parseInt(index) + 1}</Table.Td>
      <Table.Td>
        <SelectComp
          options={[
            { label: 'SCC', value: 'SCC' },
            { label: 'CC', value: 'CC' }
          ]}
          value={item?.journey_order_id?.sold_by?.toUpperCase()}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              quantity: 0,
              journey_order_id: {
                ...item?.journey_order_id,
                sold_by: e
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.sold_by}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={['Sales', 'FOC']}
          value={item?.journey_order_id?.sales_type}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                sales_type: e
              },
              payment_exception_reasons: null
            })
            setCustomerSalesData(data)
            console.log(data)
          }}
          error={error?.sales_type}
          allowDeselect={false}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={
            allPaymentReason?.data?.filter(
              item => !item?.for_internal_employees
            ) || []
          }
          value={item?.payment_exception_reasons || null}
          setValue={e => handleChange('payment_exception_reasons', e)}
          error={error?.payment_exception_reasons}
          disabled={item?.journey_order_id?.sales_type === 'Sales'}
          readOnly={
            isReadOnly || item?.journey_order_id?.sales_type === 'Sales'
          }
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          value={item?.journey_order_id?.seat}
          options={consumed_by}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                seat: e
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.seat}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          data={allProducts?.data || []}
          value={item?.product_id?.id?.toString()}
          setValue={(e, option) => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              quantity: 0,
              product_id: {
                ...option
              }
            })
            setCustomerSalesData(data)
          }}
          error={error?.product_id}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          value={item?.quantity}
          onChange={e => {
            if (
              Number(e.target.value) >
              getMaxProductQuantity(item?.product_id?.id)?.[
                item?.journey_order_id?.sold_by
              ]
            ) {
              displayNotification({
                message: `Quantity cannot be more than ${
                  getMaxProductQuantity(item?.product_id?.id)?.[
                    item?.journey_order_id?.sold_by
                  ]
                }`,
                variant: 'error'
              })
            } else {
              Number(e.target.value) >= 0 &&
                handleChange('quantity', Number(e.target.value))
            }
          }}
          error={error?.quantity}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={item?.product_id?.selling_price}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={
            parseInt(item?.quantity || 0) *
            parseInt(item?.product_id?.selling_price || 0)
          }
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          value={item?.journey_order_id?.invoice_email || ''}
          setValue={e => {
            let data = customerSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                invoice_email: e
              }
            })
            setCustomerSalesData(data)
          }}
          placeholder={'Email'}
          readOnly={isReadOnly}
          error={error?.invoice_email}
        />
      </Table.Td>
      <Table.Td>
        {!item?.id ? (
          <ActionIcon
            variant='subtle'
            color='red'
            onClick={() => {
              let data = customerSalesData
              data.splice(index, 1)
              setCustomerSalesData([...data])
            }}
          >
            <IconX size={18} />
          </ActionIcon>
        ) : null}
      </Table.Td>
    </Table.Tr>
  )
}

// eslint-disable-next-line react/display-name
const CustomerOrders = memo(
  ({
    allProducts,
    allPaymentReason,
    customerSalesData,
    activeLeg,
    setCustomerSalesData,
    errorsObj,
    legProductsQuery,
    getCustomerSalesDetailsQuery,
    addNewCustomerSales,
    isReadOnly
  }) => {
    return (
      <Box>
        <Table verticalSpacing={8} horizontalSpacing={4}>
          <Table.Thead>
            <Table.Tr c='gray.6'>
              <Table.Td>#</Table.Td>
              <Table.Td w={'8%'}>SCC/CC</Table.Td>
              <Table.Td w={'8%'}>Sales/FOC</Table.Td>
              <Table.Td w={'12%'}>Reason for FOC</Table.Td>
              <Table.Td w={'10%'}>Consumed By</Table.Td>
              <Table.Td w={'12%'}>Product Name</Table.Td>
              <Table.Td w={'8%'}>QTY</Table.Td>
              <Table.Td w={'10%'}>Price</Table.Td>
              <Table.Td w={'10%'}>Sales Value</Table.Td>
              <Table.Td w={'16%'}>Invoice</Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!getCustomerSalesDetailsQuery?.isLoading &&
            customerSalesData?.length > 0 ? (
              customerSalesData?.map((item, index) => {
                return (
                  <CustomerSalesBody
                    allProducts={allProducts}
                    allPaymentReason={allPaymentReason}
                    legProductsQuery={legProductsQuery}
                    item={item}
                    index={index}
                    error={errorsObj?.[index]}
                    customerSalesData={customerSalesData}
                    setCustomerSalesData={data => {
                      setCustomerSalesData([...data])
                    }}
                    isReadOnly={isReadOnly}
                    key={index}
                  />
                )
              })
            ) : getCustomerSalesDetailsQuery?.isLoading ||
              getCustomerSalesDetailsQuery?.isPending ? (
              <Table.Tr>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
              </Table.Tr>
            ) : (
              <Table.Tr>
                <Table.Td colSpan={11} align='center'>
                  <Text c={'dimmed'}>No records found</Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
        {!['cancelled', 'auto cancelled']?.includes(activeLeg?.status)
        ? (
          <Group justify='center' mt={'md'}>
            <Button
              size='xs'
              leftSection={<IconPlus size={16} />}
              variant='light'
              onClick={addNewCustomerSales}
            >
              Add New
            </Button>
          </Group> 
        ) : null}
      </Box>
    )
  }
)

export default CustomerOrders

import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Modal,
  Tabs,
  Text,
  Title
} from '@mantine/core'
import {
  IconAlertCircle,
  IconArrowLeft,
  IconCalendar,
  IconCheck,
  IconExclamationCircle,
  IconX
} from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import DateInputComp from '../../commonComponents/components/dateInput/DateInputComp'
import TextInputComp from '../../commonComponents/components/textInput/TextInputComp'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  bulkUpsert,
  editCompletedJourney,
  getAllDataV2,
  getAllDataV3,
  getAllDataV4,
  getAllDataWithFilters,
  hierarchicalInsert
} from '../../services/common.service'
import { getAllUsers, getCabinUsers } from '../../services/employees.service'
import Header from '../../commonComponents/layout/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import {
  compareObject,
  returnOnlyUpdatedItems
} from '../../utils/objectManuplation.utils'
import InternalOrders from './journeyEditComponents/InternalOrders'
import CustomerOrders from './journeyEditComponents/CustomerOrders'
import JourneyLegs from './journeyEditComponents/JourneyLegs'
import SummaryModal from './journeyEditComponents/SummaryModal'
import useAuthStore from '../../store/authStore'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import { createColumnHelper } from '@tanstack/react-table'

// Email validation regex pattern
const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const JourneyEditForm = () => {
  // Hooks for navigation and getting journey ID from URL
  const navigate = useNavigate()
  const { journeyId } = useParams()
  const { auth } = useAuthStore()
  const userRoleCategory = auth?.emp_category_id?.category_name;


  // State management for active journey leg and its data
  const [activeLeg, setActiveLeg] = useState({ position: 1 })
  const [legData, setLegData] = useState([{}])
  const [originalLegData, setOriginalLegData] = useState([{}])

  // State for tracking validation errors in orders
  const [orderErrorObj, setOrderErrorObj] = useState({
    internal: [],
    customer: []
  })

  // State for managing customer and internal orders
  const [customerOrders, setCustomerOrders] = useState([])
  const [internalOrders, setInternalOrders] = useState([])
  const [originalOrders, setOriginalOrders] = useState({
    internal: [],
    customer: []
  })

  // UI state management
  const [saveLoading, setSaveLoading] = useState(false)
  const [summaryModal, setSummaryModal] = useState({ modal: false })
  const [endJourneyModal, setEndJourneyModal] = useState({ modal: false })
  const queryClient = useQueryClient()

  // Add state to track original inventory before aircraft change
  const [originalInventory, setOriginalInventory] = useState([])
  const [retrievalLegs, setRetrievalLegs] = useState([])

  // Query to fetch all active products with transformed data for select components
  const allProductsQuery = useQuery({
    queryKey: ['all-products'],
    queryFn: () =>
      getAllDataV3({
        source: 'products',
        filter: { is_active: true },
        sort: { id: 'product_code', desc: false },
        page: -1
      }),
    select: data => {
      return data?.data?.map(item => ({
        ...item,
        label: item?.product_name,
        value: item?.id?.toString()
      }))
    }
  })

  // Query to fetch journey legs for the current journey
  const legsQuery = useQuery({
    queryKey: ['all-legs-for-journey', journeyId],
    queryFn: () =>
      getAllDataV3({
        source: 'journey_leg',
        page: -1,
        filter: { journey_id: journeyId },
        sort: [{ id: 'leg_number', desc: false }]
      })
  })

  // Query to fetch journey details
  const getJourneyDetails = useQuery({
    queryKey: ['get-journey-details'],
    queryFn: () =>
      getAllDataV4({ source: `items/journey/${journeyId}`, page: -1 }),
    select: data => {
      return data?.data?.[0]
    }
  })

  // Query to fetch products for the active leg
  // const legProductsQuery = useQuery({
  //   queryKey: ['leg-products', activeLeg?.id],
  //   queryFn: () => {
  //     // will get the pending retrivales if any for current aircraft
  //     return getAllDataV3({
  //       source: 'journey_leg_inventory',
  //       page: -1,
  //       filter: { journey_leg_id: activeLeg?.id }
  //     }).then(async currentLegInventory => {
  //       if (
  //         activeLeg?.leg_number == 1 &&
  //         !legsQuery?.data?.data?.[0]?.completed_by_cc &&
  //         !legsQuery?.data?.data?.[0]?.completed_by_scc
  //       ) {
  //         return await getAllDataV3({
  //           // eslint-disable-next-line no-unsafe-optional-chaining
  //           page: 1,
  //           source: 'journey_leg',
  //           sort: [
  //             { id: 'journey_date', desc: true },
  //             { id: 'departure_time', desc: true }
  //           ],
  //           filter: {
  //             retrieved: false,
  //             is_last_leg: true,
  //             completed_by_scc: true,
  //             completed_by_cc: true,
  //             aircraft_id: getJourneyDetails?.data?.aircraft_id?.id
  //           },
  //           filtersWithAction: [
  //             {
  //               key: 'journey_id',
  //               value: journeyId,
  //               action: 'neq'
  //             }
  //           ]
  //         }).then(async res => {
  //           if (res?.data?.[0]?.id) {
  //             setRetrievalLegs(res?.data)
  //             return await getAllDataV3({
  //               source: 'journey_leg_inventory',
  //               page: -1,
  //               filter: { journey_leg_id: res?.data?.[0]?.id }
  //             }).then(res => {
  //               return res?.data?.map(item => {
  //                 const existingItem = currentLegInventory?.data?.find(
  //                   i => i?.product_id?.id === item?.product_id?.id
  //                 )
  //                 return {
  //                   ...currentLegInventory?.data?.find(
  //                     i => i?.product_id?.id === item?.product_id?.id
  //                   ),
  //                   opening_cc: existingItem?.opening_cc + item?.closing_cc,
  //                   opening_scc: existingItem?.opening_scc + item?.closing_scc,
  //                   closing_cc: existingItem?.closing_cc + item?.closing_cc,
  //                   closing_scc: existingItem?.closing_scc + item?.closing_scc
  //                 }
  //               })
  //             })
  //           } else {
  //             return currentLegInventory?.data
  //           }
  //         })
  //       } else {
  //         // console.log(
  //         //   legsQuery?.data?.data?.[0]?.completed_by_cc,
  //         //   legsQuery?.data?.data?.[0]?.completed_by_scc
  //         // )

  //         return currentLegInventory?.data
  //       }
  //     })
  //   },
  //   enabled: Boolean(activeLeg?.id && legsQuery?.data?.data?.length)
  //   // select: async data => {
  //   //   const result =
  //   //   console.log(result)

  //   //   return result
  //   // }
  // })
  
  const legProductsQuery = useQuery({
    queryKey: ['leg-products', activeLeg?.id],
    queryFn: () => {
      // will get the pending retrivales if any for current aircraft
      return getAllDataV3({
        source: 'journey_leg_inventory',
        page: -1,
        filter: { journey_leg_id: activeLeg?.id }
      }).then(async currentLegInventory => {
        if (
          activeLeg?.leg_number == 1 &&
          !legsQuery?.data?.data?.[0]?.completed_by_cc &&
          !legsQuery?.data?.data?.[0]?.completed_by_scc
        ) {
          return await getAllDataV3({
            // eslint-disable-next-line no-unsafe-optional-chaining
            page: 1,
            source: 'journey_leg',
            sort: [
              { id: 'journey_date', desc: true },
              { id: 'departure_time', desc: true }
            ],
            filter: {
              retrieved: false,
              is_last_leg: true,
              completed_by_scc: true,
              completed_by_cc: true,
              aircraft_id: getJourneyDetails?.data?.aircraft_id?.id
            },
            filtersWithAction: [
              {
                key: 'journey_id',
                value: journeyId,
                action: 'neq'
              }
            ]
          }).then(async res => {
            if (res?.data?.[0]?.id) {
              setRetrievalLegs(res?.data)
              return await getAllDataV3({
                source: 'journey_leg_inventory',
                page: -1,
                filter: { journey_leg_id: res?.data?.[0]?.id }
              }).then(res => {
                return res?.data?.map(item => {
                  const existingItem = currentLegInventory?.data?.find(
                    i => i?.product_id?.id === item?.product_id?.id
                  ) || {};
  
                  return {
                    ...existingItem,
                    opening_cc: (existingItem?.opening_cc || 0) + (item?.closing_cc || 0),
                    opening_scc: (existingItem?.opening_scc || 0) + (item?.closing_scc || 0),
                    closing_cc: (existingItem?.closing_cc || 0) + (item?.closing_cc || 0),
                    closing_scc: (existingItem?.closing_scc || 0) + (item?.closing_scc || 0)
                  };
                });
              });
            } else {
              return currentLegInventory?.data;
            }
          });
        }

        // Get the index of the current active leg from legsQuery data
        const prevLegIndex = activeLeg?.position; 

        // Retrieve the ID of the previous leg by subtracting 2 from the current leg's position
        // Since arrays are 0-based, this ensures we correctly get the previous leg
        const prevLegId = legsQuery?.data?.data?.[prevLegIndex - 2]?.id; 

        // Check if the previous leg exists and if either 'completed_by_cc' or 'completed_by_scc' is false
        if (
          prevLegId &&
          Boolean(!legsQuery?.data?.data?.[activeLeg?.position - 1]?.completed_by_cc) ^
          Boolean(!legsQuery?.data?.data?.[activeLeg?.position - 1]?.completed_by_scc)
        ) {

          return await getAllDataV3({
            source: 'journey_leg_inventory',
            page: -1,
            filter: { journey_leg_id: prevLegId }
          }).then(res => {
            return res?.data?.map(item => {
              const existingItem = currentLegInventory?.data?.find(
                i => i?.product_id?.id === item?.product_id?.id
              ) || {};
  
              let updatedItem = { ...existingItem };
  
              // Update SCC fields only if completed_by_scc is false
              if (!legsQuery?.data?.data?.[prevLegIndex]?.completed_by_scc) {
                if ((existingItem?.opening_scc || 0) === 0) {
                  updatedItem.opening_scc = item?.closing_scc || 0; // Update only if existing is 0
                }
                if ((existingItem?.closing_scc || 0) === 0) {
                  updatedItem.closing_scc = item?.closing_scc || 0; // Update only if existing is 0
                }
              }
  
              // Update CC fields only if completed_by_cc is false
              if (!legsQuery?.data?.data?.[prevLegIndex]?.completed_by_cc) {
                if ((existingItem?.opening_cc || 0) === 0) {
                  updatedItem.opening_cc = item?.closing_cc || 0; // Update only if existing is 0
                }
                if ((existingItem?.closing_cc || 0) === 0) {
                  updatedItem.closing_cc = item?.closing_cc || 0; // Update only if existing is 0
                }
              }
  
              return updatedItem;
            });
          });
        }
  
        // Default return current inventory
        return currentLegInventory?.data;
      });
    },
    enabled: Boolean(activeLeg?.id && legsQuery?.data?.data?.length)
    // select: async data => {
    //   const result =
    //   console.log(result)

    //   return result
    // }
  })

  console.log(legProductsQuery?.data)

  const allPaymentExceptionReasonsQuery = useQuery({
    queryKey: ['all-payment_exception_reasons'],
    queryFn: () =>
      getAllDataWithFilters({
        source: 'payment_exception_reasons',
        filter: [{ key: 'is_active', value: true }],
        sort: { id: 'reason', desc: false },
        page: -1
      }),
    select: data => {
      return data?.data?.map(item => ({
        ...item,
        label: item?.reason,
        value: item?.reason
      }))
    }
  })

  const aircraftQuery = useQuery({
    queryKey: ['all-aircrafts'],
    queryFn: () =>
      getAllDataV3({
        source: 'aircrafts',
        page: -1,
        filter: { is_active: true }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          label: items?.tail_reg_no,
          value: `${items?.id}`
        }
      })
    }
  })

  const usersQuery = useQuery({
    queryKey: ['all-cabin-users'],
    queryFn: () => getCabinUsers({ page: -1 }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  const cockpitUsersQuery = useQuery({
    queryKey: ['all-cockpit-users'],
    queryFn: () =>
      getAllUsers({
        page: -1,
        filter: { 'emp_role_id.role_name': 'Cockpit Crew' }
      }),
    select: data => {
      return data?.data?.map(items => {
        return {
          ...items,
          label: `${items?.emp_name} (${items?.emp_code})`,
          value: `${items?.id}`
        }
      })
    }
  })

  const getOrderDetailsQuery = useQuery({
    queryKey: ['get-journey-order-details', activeLeg?.id],
    queryFn: () =>
      getAllDataV4({
        source: `items/journey_order_item?filters={"journey_order_id.journey_leg_id_eq":${activeLeg?.id}}&page=-1&sort_by=id`
      }),
    enabled: Boolean(activeLeg?.id)
  })

  const allOrdersCountOfJourneyQuery = useQuery({
    queryKey: ['get-journey-order-details-count', journeyId],
    queryFn: () =>
      getAllDataV4({
        source: `items/journey_order?filters={"journey_id_eq":${journeyId}}&fields=journey_leg_id&group_by=["journey_leg_id"]&aggregate={"count":"journey_id"}`
      }),
    enabled: Boolean(journeyId)
  })

  const getOrderSummaryQuery = useQuery({
    queryKey: ['get-order-summary', activeLeg?.id],
    queryFn: () =>
      getAllDataV2({
        source: `sales_summary`,
        filter: [{ key: 'journey_leg_id', value: activeLeg?.id }],
        page: -1
      }),
    // enabled: Boolean(activeLeg?.id && Boolean(summaryModal?.modal))
    enabled: Boolean(activeLeg?.id)
  })

  const downloadOrdersQuery = useMutation({
    mutationFn: () =>
      getAllDataV2({
        source: `sales_summary`,
        filter: [{ key: 'journey_leg_id', value: activeLeg?.id }],
        page: -1,
        download: true
      }),
    onSuccess: data => {
      if (data?.data?.[0]?.url) {
        window.open(data?.data?.[0]?.url, '_blank')
      }
    },
    onError: e => {
      console.log(e)

      notifications.show({
        title: 'Error',
        message: e?.message || 'Something went wrong',
        autoClose: 3000,
        color: 'red',
        icon: <IconX />
      })
    }
  })

  const saveJourneyMutation = useMutation({
    mutationFn: body =>
      hierarchicalInsert({
        source: ['journey_order_item', 'journey_order'],
        body
      }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Order Details Updated',
        message: 'Please wait till we update Orders',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      queryClient.invalidateQueries([
        'all-journeys',
        'all-legs',
        'leg-products',
        'get-journey-details',
        'get-journey-order-details'
      ])
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveJourneyLegsMutation = useMutation({
    mutationFn: data => bulkUpsert({ source: 'journey_leg', body: data }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Journey Details Updated',
        message: 'Please wait till we update leg info',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
    },
    onError: e => {
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  const saveCompletedJourneyMutation = useMutation({
    mutationFn: body =>
      editCompletedJourney({
        id: activeLeg?.id,
        body
      }),
    onSuccess: () => {
      notifications.update({
        id: journeyId,
        title: 'Order Details Updated',
        message: 'Please wait till we update Orders',
        color: 'green',
        loading: false,
        autoClose: 3000,
        icon: <IconCheck />
      })
      queryClient.invalidateQueries([
        'all-journeys',
        'all-legs',
        'leg-products',
        'get-journey-details',
        'get-journey-order-details'
      ])
    },
    onError: e => {
      console.log(e)
      notifications.update({
        id: journeyId,
        title: 'Sorry!',
        message: e || 'Something went wrong',
        color: 'red',
        loading: false,
        autoClose: 3000,
        icon: <IconExclamationCircle />
      })
    }
  })

  // Effect to process and organize order details when data is fetched
  useEffect(() => {
    if (getOrderDetailsQuery?.data?.data) {
      // Separate and process customer orders
      const customerOrderDetailsArray = getOrderDetailsQuery.data.data
        .filter(
          item => !item?.journey_order_id?.internal_sales && item?.quantity != 0
        )
        ?.map(item => ({
          ...item,
          journey_order_id: {
            ...item?.journey_order_id,
            sales_type: item?.payment_exception_reasons ? 'FOC' : 'Sales'
          }
        }))

      // Separate and process internal orders
      const internalOrderDetailsArray = getOrderDetailsQuery.data.data
        .filter(
          item => item?.journey_order_id?.internal_sales && item?.quantity != 0
        )
        ?.map(item => ({
          ...item,
          journey_order_id: {
            ...item?.journey_order_id,
            sales_type: item?.journey_order_id?.internal_user_id
              ? usersQuery?.data?.find(
                user => item?.journey_order_id?.internal_user_id == user.id
              )?.emp_category_id?.category_name == 'Cabin'
                ? 'Cabin'
                : 'Cockpit'
              : null
          }
        }))

      // Update state with processed orders
      setCustomerOrders([...customerOrderDetailsArray])
      setInternalOrders([...internalOrderDetailsArray])
      setOriginalOrders({
        internal: [...internalOrderDetailsArray],
        customer: [...customerOrderDetailsArray]
      })
    }
  }, [getOrderDetailsQuery?.data?.data])


  // console.log("legsQuery?.data?.data?.find(i => i?.is_last_leg)?.retrieved",legsQuery?.data?.data?.find(i => i?.is_last_leg)?.retrieved)

  // Function to validate and save journey data
  const checkErrorAndSave = async ({
    isAircraftChange = false,
    lastJourneyInventory,
    newAircraft,
    updatedLegData
  }) => {
    console.log('prev_leg_id')

    // Helper function to calculate inventory for previous legs
    const buildCurrentInventoryResult = async ({ prev_leg_id }) => {
      if (prev_leg_id?.length) {
        const allPromise = prev_leg_id?.map(item =>
          getAllDataV3({
            source: 'journey_leg_inventory',
            page: -1,
            filter: { journey_leg_id: item }
          }).then(res => res?.data)
        )
        const prevLegInventory = await Promise.all([...allPromise])
        console.log('prevLegInventory', prevLegInventory)

        // Calculate finalResult for leg chain
        const finalResult =
          prev_leg_id?.length > 1
            ? prevLegInventory?.map((allLegInv, index) => {
              let legInvArray = []
              if (index === 0) {
                legInvArray.push(allLegInv)
              } else {
                legInvArray.push(
                  allLegInv?.map(item => {
                    const prevLeg = prevLegInventory?.[index - 1]?.find(
                      inv => inv?.product_id?.id == item?.product_id?.id
                    )

                    const closing_cc = item?.opening_cc - item?.closing_cc
                    const closing_scc = item?.opening_scc - item?.closing_scc
                    console.log('prevLeg', prevLeg)
                    return {
                      ...item,
                      opening_cc: prevLeg?.closing_cc || 0,
                      opening_scc: prevLeg?.closing_scc || 0,
                      closing_cc: prevLeg?.closing_cc - closing_cc || 0,
                      closing_scc: prevLeg?.closing_scc - closing_scc || 0
                    }
                  })
                )
              }
              return legInvArray
            })
            : [prevLegInventory]

        // Only handle aircraft change logic if isAircraftChange is true
        if (isAircraftChange === true && lastJourneyInventory?.length) {
          // Store original inventory for possible revert
          setOriginalInventory(prevLegInventory)

          // Get the last leg inventory and combine with new aircraft inventory
          const lastLegInventory = finalResult[prev_leg_id?.length - 1][0]
          const combinedInventory = lastLegInventory.map(item => {
            const lastJourneyItem = lastJourneyInventory.find(
              li => li.product_id.id === item.product_id.id
            )

            return {
              ...item,
              opening_cc:
                (item.closing_cc || 0) + (lastJourneyItem?.closing_cc || 0),
              opening_scc:
                (item.closing_scc || 0) + (lastJourneyItem?.closing_scc || 0)
            }
          })

          // Calculate final inventory with current sales
          const finalInventory = legProductsQuery?.data?.map(item => {
            const arrayOfCurrProduct = [
              ...customerOrders,
              ...internalOrders
            ].filter(inv => inv?.product_id?.id == item?.product_id?.id)

            const prevLeg = combinedInventory?.find(
              i => i?.product_id?.id === item?.product_id?.id
            )

            const current_cc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const current_scc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const prev_opening_cc =
              activeLeg?.position === 1 || isAircraftChange
                ? prevLeg?.closing_cc + item?.opening_cc || 0
                : prevLeg?.closing_cc || item?.opening_cc || 0
            const prev_opening_scc =
              activeLeg?.position === 1 || isAircraftChange
                ? prevLeg?.closing_scc + item?.opening_scc || 0
                : prevLeg?.closing_scc || item?.opening_scc || 0

            return {
              ...item,
              opening_cc: prev_opening_cc,
              opening_scc: prev_opening_scc,
              closing_cc:
                current_cc > 0
                  ? (prev_opening_cc || 0) - current_cc
                  : prev_opening_cc,
              closing_scc:
                current_scc > 0
                  ? (prev_opening_scc || 0) - current_scc
                  : prev_opening_scc
            }
          })
          // return finalInventory
          return await buildFutureInventoryResult({
            currentInventory: finalInventory
          })
        }

        // If reverting aircraft change, use stored original inventory
        else if (isAircraftChange === false && originalInventory?.length) {
          // return originalInventory
          return await buildFutureInventoryResult({
            currentInventory: originalInventory
          })
        }

        // Normal inventory calculation (no aircraft change)
        else {
          const finalInventory = legProductsQuery?.data?.map(item => {
            const arrayOfCurrProduct = [
              ...customerOrders,
              ...internalOrders
            ].filter(inv => inv?.product_id?.id == item?.product_id?.id)

            const prevLeg = finalResult[prev_leg_id?.length - 1][0]?.find(
              i => i?.product_id?.id === item?.product_id?.id
            )

            const current_cc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const current_scc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const prev_opening_cc =
              (activeLeg?.position === 1 && !activeLeg?.completed_by_cc) ||
                isAircraftChange
                ? prevLeg?.closing_cc + item?.opening_cc || 0
                : prevLeg?.closing_cc === item?.opening_cc
                  ? item?.opening_cc
                  : prevLeg?.closing_cc -
                  (prevLeg?.closing_cc - item?.opening_cc) || 0
            const prev_opening_scc =
              (activeLeg?.position === 1 && !activeLeg?.completed_by_scc) ||
                isAircraftChange
                ? prevLeg?.closing_scc + item?.opening_scc || 0
                : prevLeg?.closing_scc === item?.opening_scc
                  ? item?.opening_scc
                  : prevLeg?.closing_scc -
                  (prevLeg?.closing_scc - item?.opening_scc) || 0

            return {
              ...item,
              opening_cc: prev_opening_cc,
              opening_scc: prev_opening_scc,
              closing_cc:
                current_cc > 0
                  ? (prev_opening_cc || 0) - current_cc
                  : prev_opening_cc,
              closing_scc:
                current_scc > 0
                  ? (prev_opening_scc || 0) - current_scc
                  : prev_opening_scc
            }
          })
          // return finalInventory
          return await buildFutureInventoryResult({
            currentInventory: finalInventory
          })
        }
      }
    }

    /**
     * Asynchronously builds the future inventory result for journey legs beyond the active leg.
     *
     * Fetches inventory data for future journey legs and calculates the inventory
     * based on the current inventory and the fetched data. The function computes
     * the opening and closing inventory for each product in subsequent legs.
     *
     * @param {Object} currentInventory - The current inventory state for the active journey leg.
     * @returns {Promise<Array>} A promise that resolves to an array containing the calculated
     * future inventory for each product in the subsequent journey legs.
     */

    const buildFutureInventoryResult = async ({ currentInventory }) => {
      const allPromise = legData
        ?.filter(i => i?.leg_number > activeLeg?.leg_number && !Boolean(['cancelled', 'auto cancelled']?.includes(i?.status?.toLowerCase())))
        ?.map(item =>
          getAllDataV3({
            source: 'journey_leg_inventory',
            page: -1,
            filter: { journey_leg_id: item?.id }
          }).then(res => res?.data)
        )

      const futureLegInventory = await Promise.all([...allPromise])

      const finalInventoryResult = []
      if (futureLegInventory?.length) {
        let prevArray = { original: [], modified: [] }
        let currentArray = { original: [], modified: [] }
        futureLegInventory?.map((item, index) => {
          prevArray = {
            original: currentArray.original,
            modified: currentArray.modified
          }
          currentArray = { original: [], modified: [] }
          item?.map(innerItem => {
            let calculationObj = {}
            currentArray.original.push(innerItem)
            if (index === 0) {
              calculationObj = {
                ...currentInventory?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )
              }
              prevArray.original = [...legProductsQuery.data]
              prevArray.modified = currentInventory
            } else {
              calculationObj = {
                ...prevArray.modified?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )
              }
            }
            const prevDelta = {
              cc:
                prevArray.original.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_cc -
                prevArray.modified.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_cc,
              scc:
                prevArray.original.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_scc -
                prevArray.modified.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_scc
            }

            console.log('====================================');
            console.log(calculationObj?.closing_cc)
            console.log(innerItem?.closing_cc)
            console.log(innerItem?.opening_cc)
            console.log('====================================');
            

            const result = {
              ...innerItem,
              opening_cc:
                prevArray.modified?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.journey_leg_id?.aircraft_id ==
                  innerItem?.journey_leg_id?.aircraft_id
                  ? calculationObj?.closing_cc ? calculationObj.closing_cc : 0
                  : innerItem?.opening_cc - prevDelta?.cc || 0,
              opening_scc:
                prevArray.modified?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.journey_leg_id?.aircraft_id ==
                  innerItem?.journey_leg_id?.aircraft_id
                  ? calculationObj?.closing_scc ? calculationObj.closing_scc : 0
                  : innerItem?.opening_scc - prevDelta?.scc || 0,
              closing_cc:
                prevArray.modified?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.journey_leg_id?.aircraft_id !=
                  innerItem?.journey_leg_id?.aircraft_id
                  ? innerItem?.closing_cc - prevDelta?.cc
                  : (calculationObj?.closing_cc || 0) == (innerItem?.closing_cc || 0)
                    ? // calculationObj?.closing_cc - (innerItem?.closing_cc || 0)
                    (calculationObj?.closing_cc || 0)
                    : (calculationObj?.closing_cc || 0) -
                    (innerItem?.opening_cc - innerItem?.closing_cc || 0) || 0,
              closing_scc:
                prevArray.modified?.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.journey_leg_id?.aircraft_id !=
                  innerItem?.journey_leg_id?.aircraft_id
                  ? innerItem?.closing_scc - prevDelta?.scc
                  : calculationObj?.closing_scc == innerItem?.closing_scc
                    ? // calculationObj?.closing_scc - (innerItem?.closing_scc || 0)
                    calculationObj?.closing_scc
                    : calculationObj?.closing_scc -
                    (innerItem?.opening_scc - innerItem?.closing_scc || 0) ||
                    0
            }

            if (innerItem?.product_id?.id == 55) {
              console.log(
                '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'
              )

              console.log('prevDelta', prevDelta)
              console.log('innerItem', innerItem)
              console.log('prevArray', prevArray)
              console.log('result', result)
              console.log(
                'legProductsQuery',
                prevArray.original.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_scc
              )
              console.log(
                'legProductsQuery',
                prevArray.modified.find(
                  i => i?.product_id?.id === innerItem?.product_id?.id
                )?.closing_scc
              )
              console.log(
                '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'
              )
            }

            console.log("result cooper", result)
            finalInventoryResult.push(result)
            currentArray.modified.push(result)
          })
        })
      }
      console.log('finalInventoryResult', finalInventoryResult)
      console.log('currentInventory', currentInventory)

      return [...currentInventory, ...finalInventoryResult]
    }

    // Check for unsaved leg changes
    if (
      !compareObject(
        legData?.[activeLeg?.position - 1],
        originalLegData?.[activeLeg?.position - 1]
      )
    ) {
      notifications.show({
        title: 'Warning',
        message:
          'There are some leg changes not saved. Please save it or reset it.',
        color: 'red',
        icon: <IconAlertCircle />
      })
      return
    }

    // Validate all orders and collect errors
    let isError = false
    let resultError = { customer: [], internal: [] }

    // Validate internal orders (crew orders)
    internalOrders.forEach(item => {
      let error = {}
      if (!item?.journey_order_id?.sold_by) {
        error.sold_by = 'Please select sold by'
        isError = true
      }
      if (!item?.journey_order_id?.internal_user_id) {
        error.internal_user_id = 'Please select seat'
        isError = true
      }
      if (!item?.payment_exception_reasons) {
        error.payment_exception_reasons = 'Please select reason'
        isError = true
      }
      if (!item?.journey_order_id?.sales_type) {
        error.sales_type = 'Please select Type'
        isError = true
      }
      if (!item?.product_id?.id) {
        error.product_id = 'Please select product'
        isError = true
      }
      if (!item?.quantity?.toString()) {
        error.quantity = 'Please enter quantity'
        isError = true
      }
      resultError.internal.push(error)
    })

    // Validate customer orders
    customerOrders.forEach(item => {
      let error = {}
      if (!item?.journey_order_id?.sold_by) {
        error.sold_by = 'Please select sold by'
        isError = true
      }
      if (!item?.journey_order_id?.seat) {
        error.seat = 'Please select seat'
        isError = true
      }
      if (
        item?.journey_order_id?.sales_type === 'FOC' &&
        !item?.payment_exception_reasons
      ) {
        error.payment_exception_reasons = 'Please select reason'
        isError = true
      }
      if (!item?.journey_order_id?.sales_type) {
        error.sales_type = 'Please select Type'
        isError = true
      }
      if (!item?.product_id?.id) {
        error.product_id = 'Please select product'
        isError = true
      }
      if (!item?.quantity?.toString()) {
        error.quantity = 'Please enter quantity'
        isError = true
      }
      if (
        item?.journey_order_id?.invoice_email &&
        !regex.test(item?.journey_order_id?.invoice_email)
      ) {
        error.invoice_email = 'Please enter valid email address'
        isError = true
      }
      resultError.customer.push(error)
    })
    setOrderErrorObj({ ...resultError })

    // If there are errors, show a notification and prevent saving
    if (isError) {
      setSaveLoading(false)
      notifications.show({
        title: 'Error',
        message: 'Please fill all the mandatory fields',
        color: 'red',
        icon: <IconX />
      })
      return
    }

    // If there are no errors, show a notification and start saving
    if (!isError) {
      notifications.show({
        id: journeyId,
        title: 'Updating Orders...',
        message: 'Please do not close anything.',
        loading: true,
        autoClose: false
      })
      let legInventory = []
      let result = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...internalOrders?.map(item => ({
          ...item,
          sold_by: item?.journey_order_id?.sold_by,
          seat: item?.journey_order_id?.seat,
          sales_type: item?.journey_order_id?.sales_type,
          internal_sales: item?.journey_order_id?.internal_sales
        })),
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...customerOrders?.map(item => ({
          ...item,
          sold_by: item?.journey_order_id?.sold_by,
          seat: item?.journey_order_id?.seat,
          sales_type: item?.journey_order_id?.sales_type,
          internal_sales: item?.journey_order_id?.internal_sales
        }))
      ]
      let body = [
        ...result.map(value => ({
          journey_order: {
            ...value?.journey_order_id,
            unique_order_uuid:
              value?.journey_order_id?.unique_order_uuid || crypto.randomUUID(),
            journey_id: Number(journeyId),
            journey_leg_id: activeLeg?.id,
            internal_sales: value?.internal_sales || false,
            payment_exception_reasons: value?.payment_exception_reasons,
            price: Number(value?.quantity) * Number(value?.unit_price),
            quantity: value?.quantity,
            journey_order_item: [
              {
                id: value?.id,
                journey_id: journeyId,
                journey_leg_id: activeLeg?.id,
                product_id: value?.product_id?.id,
                journey_order_id: value?.journey_order_id?.id,
                product_name: value?.product_id.product_name,
                price: Number(value?.quantity) * Number(value?.unit_price),
                product_category:
                  value?.product_id.category_id?.id ||
                  value?.product_id.category_id,
                product_code: value?.product_id.product_code,
                payment_exception_reasons: value?.payment_exception_reasons,
                quantity: value?.quantity,
                unit_price: value?.product_id.selling_price,
                created_by: value?.created_by?.id,
                updated_by: value?.updated_by?.id
              }
            ]
          }
        }))
      ]

      if (retrievalLegs?.length) {
        body = [
          ...body,
          ...retrievalLegs.map(item => ({
            journey_leg: {
              ...item,
              journey_id: item?.journey_id?.id,
              depart_airport_id: item?.depart_airport_id?.id,
              arrive_airport_id: item?.arrive_airport_id?.id,
              senior_cabin_crew_id: item?.senior_cabin_crew_id?.id,
              cabin_crew_id: item?.cabin_crew_id?.id,
              created_by: item?.created_by?.id,
              updated_by: item?.updated_by?.id,
              aircraft_id: item?.aircraft_id?.id,
              retrieved: true
            }
          }))
        ]
      }

      if (getJourneyDetails?.data?.journey_status !== 'completed') {
        let prev_leg_id
        if (isAircraftChange) {
          prev_leg_id = await getAllDataV3({
            // eslint-disable-next-line no-unsafe-optional-chaining
            page: 1,
            source: 'journey_leg',
            sort: [
              { id: 'journey_date', desc: true },
              { id: 'departure_time', desc: true }
            ],
            filter: {
              retrieved: false,
              is_last_leg: true,
              completed_by_scc: true,
              completed_by_cc: true,
              aircraft_id: isAircraftChange
                ? newAircraft
                : getJourneyDetails?.data?.aircraft_id?.id
            },
            filtersWithAction: [
              {
                key: 'journey_id',
                value: journeyId,
                action: 'neq'
              }
            ]
          }).then(res => {
            if (res?.data?.[0]?.id) {
              // setRetrievalLegs([...retrievalLegs, res?.data?.[0]])
              return [res?.data?.[0]?.id]
            }
          })
        } else {
          prev_leg_id = legData
            ?.filter(item => Number(item?.leg_number) < activeLeg?.position)
            ?.map(item => item?.id)
        }
        if (prev_leg_id?.length) {
          legInventory = await buildCurrentInventoryResult({ prev_leg_id })
        } else {
          const currentInventory = legProductsQuery?.data?.map(item => {
            const arrayOfCurrProduct = [
              ...customerOrders,
              ...internalOrders
            ].filter(inv => inv?.product_id?.id == item?.product_id?.id)
            const current_cc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

            const current_scc =
              arrayOfCurrProduct
                ?.filter(
                  item =>
                    item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                )
                .reduce((acc, curr) => acc + curr?.quantity, 0) || 0
            return {
              ...item,
              closing_cc: (item?.opening_cc || 0) - current_cc,
              closing_scc: (item?.opening_scc || 0) - current_scc
            }
          })
          console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 1:')
          console.log(currentInventory)

          legInventory = await buildFutureInventoryResult({ currentInventory })
          console.log('legInventory', legInventory)
        }

        const currentLegData = updatedLegData?.length
          ? updatedLegData?.find(item => item?.id == activeLeg?.id)
          : legData?.find(item => item?.id == activeLeg?.id)
        body = [
          ...body,
          {
            journey_leg: {
              ...currentLegData,
              completed_by_cc: true,
              completed_by_scc: true
            }
          },
          // ...retrievalLegs.map(item => ({
          //   journey_leg: {
          //     ...item,
          //     retrieved: true
          //   }
          // })),
          ...legInventory.map(item => ({
            journey_leg_inventory: {
              ...item,
              journey_leg_id: item?.journey_leg_id?.id,
              product_id: item?.product_id?.id,
              created_by: item?.created_by?.id,
              updated_by: item?.updated_by?.id
            }
          }))
        ]

        console.log('body', body)

        saveJourneyMutation.mutate(body, {
          onSettled: () => {
            setSaveLoading(false)
            setEndJourneyModal({})
          }
        })
      } else {
        if (
          legsQuery?.data?.data?.length &&
          !legsQuery?.data?.data?.find(i => i?.is_last_leg)?.retrieved
        ) {
          const prev_leg_id = legData
            ?.filter(item => Number(item?.leg_number) < activeLeg?.position)
            ?.map(item => item?.id)
          if (prev_leg_id?.length) {
            legInventory = await buildCurrentInventoryResult({ prev_leg_id })
          } else {
            const currentInventory = legProductsQuery?.data?.map(item => {
              const arrayOfCurrProduct = [
                ...customerOrders,
                ...internalOrders
              ].filter(inv => inv?.product_id?.id == item?.product_id?.id)
              const current_cc =
                arrayOfCurrProduct
                  ?.filter(
                    item =>
                      item?.journey_order_id?.sold_by?.toLowerCase() === 'cc'
                  )
                  .reduce((acc, curr) => acc + curr?.quantity, 0) || 0

              const current_scc =
                arrayOfCurrProduct
                  ?.filter(
                    item =>
                      item?.journey_order_id?.sold_by?.toLowerCase() === 'scc'
                  )
                  .reduce((acc, curr) => acc + curr?.quantity, 0) || 0
              return {
                ...item,
                closing_cc: (item?.opening_cc || 0) - current_cc,
                closing_scc: (item?.opening_scc || 0) - current_scc
              }
            })
            console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 2')
            console.log(currentInventory)
            legInventory = await buildFutureInventoryResult({
              currentInventory
            })
          }

          const currentLegData = updatedLegData?.length
            ? updatedLegData?.find(item => item?.id == activeLeg?.id)
            : legData?.find(item => item?.id == activeLeg?.id)
          body = [
            ...body,
            {
              journey_leg: {
                ...currentLegData,
                completed_by_cc: true,
                completed_by_scc: true
              }
            },
            ...legInventory.map(item => ({
              journey_leg_inventory: {
                ...item,
                journey_leg_id: item?.journey_leg_id?.id,
                product_id: item?.product_id?.id,
                created_by: item?.created_by?.id,
                updated_by: item?.updated_by?.id
              }
            }))
          ]

          console.log('body', body)

          saveJourneyMutation.mutate(body, {
            onSettled: () => {
              setSaveLoading(false)
              setEndJourneyModal({})
            }
          })
        } else {
          let completedBody = returnOnlyUpdatedItems(
            [...originalOrders.customer, ...originalOrders.internal],
            [...customerOrders, ...internalOrders]
          ).map(value => ({
            journey_order: {
              ...value?.journey_order_id,
              unique_order_uuid:
                value?.journey_order_id?.unique_order_uuid ||
                crypto.randomUUID(),
              journey_id: Number(journeyId),
              journey_leg_id: activeLeg?.id,
              internal_sales: value?.internal_sales || false,
              payment_exception_reasons: value?.payment_exception_reasons,
              price: Number(value?.quantity) * Number(value?.unit_price),
              quantity: value?.quantity,
              journey_order_item: [
                {
                  id: value?.id,
                  journey_id: journeyId,
                  journey_leg_id: activeLeg?.id,
                  product_id: value?.product_id?.id,
                  journey_order_id: value?.journey_order_id?.id,
                  product_name: value?.product_id.product_name,
                  price: Number(value?.quantity) * Number(value?.unit_price),
                  product_category:
                    value?.product_id.category_id?.id ||
                    value?.product_id.category_id,
                  product_code: value?.product_id.product_code,
                  payment_exception_reasons: value?.payment_exception_reasons,
                  quantity: value?.quantity,
                  unit_price: value?.product_id.selling_price,
                  created_by: value?.created_by?.id,
                  updated_by: value?.updated_by?.id
                }
              ]
            }
          }))
          console.log('completedBody', completedBody)
          if (completedBody?.length) {
            console.log('completedBody', body)

            saveCompletedJourneyMutation.mutate(body, {
              onSettled: () => {
                setSaveLoading(false)
                setEndJourneyModal({})
              }
            })
          } else {
            notifications.update({
              id: journeyId,
              title: 'No Updates',
              message: 'Please edit or add items to save',
              loading: false,
              autoClose: 3000
            })
            setEndJourneyModal({ modal: false })
            setSaveLoading(false)
          }
        }
      }
    }
  }

  // Handler for aircraft changes
  const handleAircraftChange = ({
    oldAircraft,
    newAircraft,
    position,
    lastJourneyInventory,
    updatedLegData
  }) => {
    // If reverting to original aircraft
    const isRevert = originalLegData[position - 1]?.aircraft_id === newAircraft
    // console.log('activeLeg > 1', JSON.stringify(activeLeg));
    setLegData(updatedLegData)

    // will get all inventory for all legs
    // will check if any retrivales is there for selected aircraft
    // if any then will change all the inventory else will use the current inventory
    //

    saveJourneyLegsMutation.mutate(updatedLegData, {
      onSettled: () => {
        // Recalculate inventory
        checkErrorAndSave({
          isAircraftChange: !isRevert,
          lastJourneyInventory: lastJourneyInventory?.data,
          newAircraft,
          updatedLegData
        })
      }
    })
    // checkErrorAndSave({
    //   isAircraftChange: !isRevert,
    //   lastJourneyInventory: lastJourneyInventory?.data,
    //   newAircraft
    // })
    // await queryClient.invalidateQueries({ queryKey: ['all-legs-for-journey', journeyId] })
  }

  const canEditLeg = (() => {
    const role = userRoleCategory?.toLowerCase();
    const { completed_by_cc, completed_by_scc } = activeLeg;
  
    // Super Admin can always edit
    if (role?.toLowerCase() === "super admin") return true;
  
    // If the journey is completed, only super admin can edit
    if (getJourneyDetails?.data?.journey_status?.toLowerCase() === "completed") return false;
  
    // Admin can edit only if at least one of completed_by_cc or completed_by_scc is false
    if (role.includes("admin") && role !== "super admin") {
      return !completed_by_cc || !completed_by_scc; // At least one is false
    }
  
    // Default: No edit permission
    return false;
  })();

  const columnHelper = createColumnHelper()
  const column = [
    columnHelper.accessor('journey_date', {
      header: 'Journey Date'
    }),
    columnHelper.accessor('route', {
      header: 'Route'
    }),
    columnHelper.accessor('scc_or_cc', {
      header: 'SCC/CC'
    }),
    columnHelper.accessor('reason_for_foc', {
      header: 'Reason for FOC',
      cell: value => value.row.original.reason_for_foc || '-'
    }),
    columnHelper.accessor('consumed_by', {
      header: 'Consumed by'
    }),
    columnHelper.accessor('product', {
      header: 'Product'
    }),
    columnHelper.accessor('qty', {
      header: 'Quantity'
    }),
    columnHelper.accessor('price', {
      header: 'Selling Price',
      cell: ({ row }) =>
        row.original?.price ? `₹ ${row.original?.price}` : '₹ 0'
    }),
    columnHelper.accessor('foc_value', {
      header: 'FOC Value',
      cell: ({ row }) =>
        row.original?.foc_value ? `₹ ${row.original?.foc_value}` : '₹ 0'
    }),
    columnHelper.accessor('sales_value', {
      header: 'Sales Value',
      cell: ({ row }) =>
        row.original?.sales_value ? `₹ ${row.original?.sales_value}` : '₹ 0'
    })
  ]


  return (
    <Box>
      <Group gap={0}>
        <ActionIcon variant='transparent' onClick={() => navigate('/journeys')}>
          <IconArrowLeft size={20} color='gray' />
        </ActionIcon>
        <Header title={'Sales Entry'} />
      </Group>

      <Flex direction={'column'} gap={4} mt={'md'}>
        <Box flex={1}>
          <Grid>
            <Grid.Col span={3}>
              <DateInputComp
                isRequired
                label={'Journey Date'}
                value={
                  getJourneyDetails?.data?.journey_date
                    ? new Date(getJourneyDetails?.data?.journey_date)
                    : null
                }
                rightSection={<IconCalendar size={16} />}
                placeholder={'Select Journey Date'}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInputComp
                isRequired
                label={'Sector'}
                placeholder={'Sector'}
                value={getJourneyDetails?.data?.sector_pattern}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <JourneyLegs
                setActiveLeg={setActiveLeg}
                journeyDate={getJourneyDetails?.data?.journey_date}
                activeLeg={activeLeg}
                legData={legData}
                setLegData={setLegData}
                legsQuery={legsQuery}
                usersQuery={usersQuery}
                setOriginalLegData={setOriginalLegData}
                originalLegData={originalLegData}
                aircraftQuery={aircraftQuery}
                journeysOrderCount={allOrdersCountOfJourneyQuery?.data?.data}
                isAddNew={
                  getJourneyDetails?.data?.journey_status !== 'completed'
                }
                onAircraftChange={handleAircraftChange}
                canDelete={canEditLeg}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Tabs defaultValue={'customer_sales'}>
                <Tabs.List style={{ position: 'relative' }}>
                  <Tabs.Tab value='customer_sales'>Customer Sales</Tabs.Tab>
                  <Tabs.Tab value='internal'>{`Internal (cabin/cockpit)`}</Tabs.Tab>
                  <Box style={{ position: 'absolute', right: 0 }}>
                    <Button
                      variant='transparent'
                      size='xs'
                      onClick={() => setSummaryModal({ modal: true })}
                    >
                      View Summary
                    </Button>
                  </Box>
                </Tabs.List>
                <Tabs.Panel value='customer_sales'>
                  {canEditLeg ? (
                    <CustomerOrders
                      allProducts={allProductsQuery}
                      allPaymentReason={allPaymentExceptionReasonsQuery}
                      activeLeg={activeLeg}
                      usersQuery={usersQuery}
                      legProductsQuery={legProductsQuery}
                      // checkErrorAndSave={checkErrorAndSave}
                      isReadOnly={
                        !compareObject(
                          legData?.[activeLeg?.position - 1],
                          originalLegData?.[activeLeg?.position - 1]
                        )
                      }
                      addNewCustomerSales={() =>
                        setCustomerOrders([
                          ...customerOrders,
                          {
                            journey_id: journeyId,
                            journey_leg_id: activeLeg?.id,
                            journey_order_id: {
                              internal_sales: false,
                              purchase_date: activeLeg?.journey_date
                            }
                          }
                        ])
                      }
                      customerSalesData={[...customerOrders]}
                      setCustomerSalesData={setCustomerOrders}
                      errorsObj={orderErrorObj.customer}
                      getCustomerSalesDetailsQuery={getOrderDetailsQuery}
                    />
                  ) : (
                    <ReactTable
                      rowData={getOrderSummaryQuery?.data?.data?.filter(order => order.internal_sales == false) || []}
                      columnData={column}
                      useApiPagination={false}
                      loading={getOrderSummaryQuery?.isLoading}
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value='internal'>
                  {canEditLeg ? (
                    <InternalOrders
                      allProducts={allProductsQuery}
                      allPaymentReason={allPaymentExceptionReasonsQuery}
                      activeLeg={activeLeg}
                      usersQuery={usersQuery}
                      cockpitUsersQuery={cockpitUsersQuery}
                      legProductsQuery={legProductsQuery}
                      // checkErrorAndSave={checkErrorAndSave}
                      internalSalesData={internalOrders}
                      setInternalSalesData={setInternalOrders}
                      errorsObj={orderErrorObj.internal}
                      getInternalSalesDetails={getOrderDetailsQuery}
                      addNewInternalSales={() =>
                        setInternalOrders([
                          ...internalOrders,
                          {
                            journey_id: journeyId,
                            journey_leg_id: activeLeg?.id,
                            journey_order_id: {
                              internal_sales: true,
                              seat: '',
                              purchase_date: activeLeg?.journey_date
                            }
                          }
                        ])
                      }
                      isReadOnly={
                        !compareObject(
                          legData?.[activeLeg?.position - 1],
                          originalLegData?.[activeLeg?.position - 1]
                        )
                      }
                    />
                  ) : (
                    <ReactTable
                      rowData={getOrderSummaryQuery?.data?.data?.filter(order => order.internal_sales == true) || []}
                      columnData={column}
                      useApiPagination={false}
                      loading={getOrderSummaryQuery.isLoading}
                    />
                  )}
                </Tabs.Panel>
              </Tabs>

              {(userRoleCategory?.toLowerCase() === "super admin" || getJourneyDetails?.data?.journey_status.toLowerCase() !== 'completed') && (!['cancelled', 'auto canceled']?.includes(activeLeg?.status)) && (
                <Group justify='flex-end' mt={'md'}>
                  <Button
                    size='xs'
                    variant='outline'
                    color='gray'
                    onClick={() => {
                      setCustomerOrders([
                        ...getOrderDetailsQuery.data.data
                          .filter(item => !item?.journey_order_id?.internal_sales)
                          .map(item => ({
                            ...item,
                            journey_order_id: {
                              ...item?.journey_order_id,
                              sales_type: item?.payment_exception_reasons
                                ? 'FOC'
                                : 'Sales'
                            }
                          }))
                      ])
                      setInternalOrders([
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...getOrderDetailsQuery.data.data
                          .filter(item => item?.journey_order_id?.internal_sales)
                          ?.map(item => ({
                            ...item,
                            journey_order_id: {
                              ...item?.journey_order_id,
                              sales_type: item?.journey_order_id?.internal_user_id
                                ? usersQuery?.data?.find(
                                  user =>
                                    item?.journey_order_id?.internal_user_id ==
                                    user.id
                                )?.emp_category_id?.category_name == 'Cabin'
                                  ? 'Cabin'
                                  : 'Cockpit'
                                : null
                            }
                          }))
                      ])
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    size='xs'
                    color='teal'
                    onClick={async () => {
                      if (
                        activeLeg?.is_last_leg &&
                        activeLeg?.journey_status !== 'Completed'
                      ) {
                        setEndJourneyModal({ modal: true })
                      } else {
                        setSaveLoading(true)
                        checkErrorAndSave({
                          isAircraftChange: false,
                          lastJourneyInventory: null
                        })
                      }
                    }}
                  // loading={saveLoading}
                  >
                    {activeLeg?.is_last_leg &&
                      activeLeg?.journey_status !== 'Completed'
                      ? 'End Journey'
                      : 'Save'}
                  </Button>
                </Group>

              )}
              {/* <Group justify='flex-end' mt={'md'}>
                <Button
                  size='xs'
                  variant='outline'
                  color='gray'
                  onClick={() => {
                    setCustomerOrders([
                      ...getOrderDetailsQuery.data.data
                        .filter(item => !item?.journey_order_id?.internal_sales)
                        .map(item => ({
                          ...item,
                          journey_order_id: {
                            ...item?.journey_order_id,
                            sales_type: item?.payment_exception_reasons
                              ? 'FOC'
                              : 'Sales'
                          }
                        }))
                    ])
                    setInternalOrders([
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      ...getOrderDetailsQuery.data.data
                        .filter(item => item?.journey_order_id?.internal_sales)
                        ?.map(item => ({
                          ...item,
                          journey_order_id: {
                            ...item?.journey_order_id,
                            sales_type: item?.journey_order_id?.internal_user_id
                              ? usersQuery?.data?.find(
                                user =>
                                  item?.journey_order_id?.internal_user_id ==
                                  user.id
                              )?.emp_category_id?.category_name == 'Cabin'
                                ? 'Cabin'
                                : 'Cockpit'
                              : null
                          }
                        }))
                    ])
                  }}
                >
                  Reset
                </Button>
                <Button
                  size='xs'
                  color='teal'
                  onClick={async () => {
                    if (
                      activeLeg?.is_last_leg &&
                      activeLeg?.journey_status !== 'Completed'
                    ) {
                      setEndJourneyModal({ modal: true })
                    } else {
                      setSaveLoading(true)
                      checkErrorAndSave({
                        isAircraftChange: false,
                        lastJourneyInventory: null
                      })
                    }
                  }}
                // loading={saveLoading}
                >
                  {activeLeg?.is_last_leg &&
                    activeLeg?.journey_status !== 'Completed'
                    ? 'End Journey'
                    : 'Save'}
                </Button>
              </Group> */}
            </Grid.Col>
          </Grid>
        </Box>
      </Flex>
      <Modal
        opened={endJourneyModal?.modal}
        onClose={() => setEndJourneyModal({ modal: false })}
        title={<Title size={'md'}>End Journey?</Title>}
      >
        <Text>Once confirmed the journey will be marked as completed.</Text>
        <Group justify='flex-end' mt={'md'}>
          <Button
            color={'gray'}
            variant='light'
            size='xs'
            onClick={() => setEndJourneyModal({ modal: false })}
          >
            Cancel
          </Button>
          <Button
            color={'teal'}
            size='xs'
            loading={saveLoading}
            onClick={() => {
              setSaveLoading(true)
              checkErrorAndSave({
                isAircraftChange: false,
                lastJourneyInventory: null
              })
            }}
          >
            Confirm
          </Button>
        </Group>
      </Modal>
      <SummaryModal
        opened={summaryModal?.modal}
        onDownload={downloadOrdersQuery}
        downloadLoading={downloadOrdersQuery?.isPending}
        onClose={setSummaryModal}
        data={getOrderSummaryQuery}
      />
    </Box>
  )
}

export default JourneyEditForm

import { notifications } from '@mantine/notifications'
import { displayNotification } from '../commonComponents/notifications/displayNotification'
import useAuthStore from '../store/authStore'
import { apiCallProtected } from './axios'
import { Button } from '@mantine/core'
import { WARNING_TIMEOUT, IDLE_TIMEOUT } from './timeout'
import { postAPI } from '../services/apiCall/postAPI'

const logout = async () => {
  try {
    await postAPI('/logout') // ✅ API call for logout
  } catch (error) {
    console.error('Logout API failed:', error)
  } finally {
    useAuthStore.getState().resetAuth() // ✅ Reset authentication state
  }
}

// const logout = useAuthStore?.getState().resetAuth
let timer = null
let warningTimer = null
let countdownTimer = null

const startCountdown = initialSeconds => {
  let remainingSeconds = initialSeconds
  countdownTimer = setInterval(() => {
    remainingSeconds -= 1
    notifications.clean()

    displayNotification({
      withCloseButton: false,
      variant: 'error',
      id: 'time-reset',
      message: (
        <span>
          <div style={{ marginBottom: 10 }}>
            You have been idle for {IDLE_TIMEOUT / 60000} minutes. You will
            automatically log out in {remainingSeconds} seconds.
          </div>
          <Button
            size='compact-xs'
            variant='white'
            onClick={() => {
              notifications.clean()
              displayNotification({
                message: <span>Your session has been reset!</span>,
                variant: 'success',
                autoClose: true,
                id: 'time-reset-success'
              })
              clearInterval(countdownTimer)
              resetIdleTime()
            }}
          >
            Click to reset!
          </Button>
        </span>
      ),
      autoClose: false
    })
    if (remainingSeconds <= 0) {
      notifications.clean()
      clearInterval(countdownTimer)
      logout()
    }
  }, 1000)
}

export const resetIdleTime = () => {
  
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  if (warningTimer) {
    clearTimeout(warningTimer)
    warningTimer = null
  }
  if (countdownTimer) {
    clearInterval(countdownTimer)
    countdownTimer = null
    notifications.clean()
    displayNotification({
      message: <span>Your session has been reset!</span>,
      variant: 'success',
      autoClose: true,
      id: 'time-reset-success'
    })
  }

  warningTimer = setTimeout(() => {
    // displayNotification({
    //   withCloseButton: false,
    //   message: (
    //     <span>
    //       <div style={{ marginBottom: 10 }}>
    //         You have been idle for 14 minutes. You will automatically logggg out in 59 seconds.
    //       </div>
    //       <Button
    //         size="compact-xs"
    //         variant="white"
    //         onClick={() => {
    //           notifications.clean();
    //           displayNotification({
    //             message: <span>Your session has been reset!</span>,
    //             variant: "success",
    //             autoClose: true,
    //             id: "time-reset-success",
    //           });
    //           clearInterval(countdownTimer);
    //           resetIdleTime();
    //         }}
    //       >
    //         Click to reset!
    //       </Button>
    //     </span>
    //   ),
    //   variant: "error",
    //   autoClose: false,
    //   id: "time-reset",
    // });
    startCountdown(59)
  }, WARNING_TIMEOUT)

  timer = setTimeout(() => {
    logout()
  }, IDLE_TIMEOUT)
}

const requestInterceptor = () =>
  apiCallProtected.interceptors.request.use(
    config => {
      const authObj = useAuthStore?.getState().auth
      if (authObj?.access_token?.length) {
        config.headers.Authorization = `${authObj?.token_type} ${authObj?.access_token}`
        resetIdleTime()
      }
      return config
    },
    error => Promise.reject(error)
  )

export default requestInterceptor

/* eslint-disable react/display-name */
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Skeleton,
  Table,
  Text
} from '@mantine/core'
import { IconAlertCircle, IconPlus, IconX } from '@tabler/icons-react'
import TextInputComp from '../../../commonComponents/components/textInput/TextInputComp'
import SelectComp from '../../../commonComponents/components/select/SelectComp'
import { notifications } from '@mantine/notifications'
import { memo } from 'react'
import { displayNotification } from '../../../commonComponents/notifications/displayNotification'

const InternalSalesBody = ({
  allProducts,
  allPaymentReason,
  setInternalSalesData,
  internalSalesData,
  legProductsQuery,
  usersQuery,
  cockpitUsersQuery,
  isReadOnly,
  item,
  error,
  index
}) => {
  const handleChange = (field, val) => {
    let data = internalSalesData
    data.splice(index, 1, {
      ...item,
      [field]: val
    })
    setInternalSalesData(data)
  }

  const getMaxProductQuantity = productId => {
    if (legProductsQuery?.data?.length) {
      const product = legProductsQuery?.data?.find(
        item => item?.product_id?.id == productId
      )

      const sold_quantity = internalSalesData
        ?.filter(i => i?.product_id?.id == productId)
        ?.reduce(
          (acc, curr) => ({
            CC:
              curr?.journey_order_id?.sold_by == 'CC'
                ? acc.CC + curr.quantity
                : acc.CC,
            SCC:
              curr?.journey_order_id?.sold_by == 'SCC'
                ? acc.SCC + curr.quantity
                : acc.SCC
          }),
          { CC: 0, SCC: 0 }
        )

      return {
        CC: product?.opening_cc - (sold_quantity?.CC || 0) || 0,
        SCC: product?.opening_scc - (sold_quantity?.SCC || 0) || 0
      }
    }
  }

  return (
    <Table.Tr
      onClick={() => {
        if (isReadOnly) {
          notifications.show({
            title: 'Warning',
            message:
              'There are some leg changes not saved. Please save it or reset it.',
            color: 'red',
            icon: <IconAlertCircle />
          })
        }
      }}
      style={{ background: item?.quantity == 0 ? '#f88' : '' }}
    >
      <Table.Td>{parseInt(index) + 1}</Table.Td>
      <Table.Td>
        <SelectComp
          options={[
            { label: 'SCC', value: 'SCC' },
            { label: 'CC', value: 'CC' }
          ]}
          value={item?.journey_order_id?.sold_by?.toUpperCase()}
          setValue={e => {
            let data = internalSalesData
            data.splice(index, 1, {
              ...item,
              quantity: 0,
              journey_order_id: {
                ...item?.journey_order_id,
                sold_by: e
              }
            })
            setInternalSalesData(data)
          }}
          error={error?.sold_by}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={['Cabin', 'Cockpit']}
          value={item?.journey_order_id?.sales_type}
          setValue={e => {
            let data = internalSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                sales_type: e,
                internal_user_id: null
              }
            })
            setInternalSalesData(data)
          }}
          error={error?.sales_type}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={
            allPaymentReason?.data?.filter(
              item => item?.for_internal_employees
            ) || []
          }
          value={item?.payment_exception_reasons}
          setValue={e => handleChange('payment_exception_reasons', e)}
          error={error?.payment_exception_reasons}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          value={item?.journey_order_id?.internal_user_id?.toString() || null}
          options={
            item?.journey_order_id?.sales_type === 'Cabin'
              ? usersQuery?.data || []
              : item?.journey_order_id?.sales_type === 'Cockpit'
              ? cockpitUsersQuery?.data || []
              : []
          }
          setValue={e => {
            let data = internalSalesData
            data.splice(index, 1, {
              ...item,
              journey_order_id: {
                ...item?.journey_order_id,
                internal_user_id: e
              }
            })
            setInternalSalesData(data)
          }}
          error={error?.seat}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <SelectComp
          options={allProducts?.data || []}
          value={item?.product_id?.id?.toString()}
          setValue={(e, option) => {
            let data = internalSalesData
            data.splice(index, 1, {
              ...item,
              quantity: 0,
              product_id: {
                ...option
              }
            })
            setInternalSalesData(data)
          }}
          error={error?.product_id}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          onChange={e => {
            if (
              Number(e.target.value) >
              getMaxProductQuantity(item?.product_id?.id)?.[
                item?.journey_order_id?.sold_by
              ]
            ) {
              displayNotification({
                message: `Quantity cannot be more than ${
                  getMaxProductQuantity(item?.product_id?.id)?.[
                    item?.journey_order_id?.sold_by
                  ]
                }`,
                variant: 'error'
              })
            } else {
              Number(e.target.value) >= 0 &&
                handleChange('quantity', Number(e.target.value))
            }
          }}
          value={item?.quantity}
          error={error?.quantity}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={item?.product_id?.selling_price}
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        <TextInputComp
          leftSection='₹'
          value={
            Number(item?.product_id?.selling_price || 0) *
            Number(item?.quantity || 0)
          }
          readOnly={isReadOnly}
        />
      </Table.Td>
      <Table.Td>
        {item?.id ? null : (
          <ActionIcon
            variant='subtle'
            color='red'
            onClick={() => {
              let data = internalSalesData
              data.splice(index, 1)
              setInternalSalesData([...data])
            }}
          >
            <IconX size={18} />
          </ActionIcon>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

const InternalOrders = memo(
  ({
    allProducts,
    allPaymentReason,
    usersQuery,
    cockpitUsersQuery,
    activeLeg,
    legProductsQuery,
    errorsObj,
    getInternalSalesDetails,
    internalSalesData,
    setInternalSalesData,
    addNewInternalSales,
    isReadOnly
  }) => {
    return (
      <Box>
        <Table verticalSpacing={8} horizontalSpacing={4}>
          <Table.Thead>
            <Table.Tr c='gray.6'>
              <Table.Td>#</Table.Td>
              <Table.Td w={'8%'}>SCC/CC</Table.Td>
              <Table.Td w={'8%'}>Cabin/Cockpit</Table.Td>
              <Table.Td w={'14%'}>Reason for FOC</Table.Td>
              <Table.Td w={'20%'}>Consumed By</Table.Td>
              <Table.Td w={'20%'}>Product Name</Table.Td>
              <Table.Td w={'6%'}>QTY</Table.Td>
              <Table.Td w={'8%'}>Price</Table.Td>
              <Table.Td w={'8%'}>FOC Value</Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {!getInternalSalesDetails?.isLoading &&
            internalSalesData?.length > 0 ? (
              internalSalesData?.map((item, index) => {
                return (
                  <InternalSalesBody
                    allProducts={allProducts}
                    allPaymentReason={allPaymentReason}
                    index={index}
                    legProductsQuery={legProductsQuery}
                    internalSalesData={internalSalesData}
                    setInternalSalesData={data => {
                      console.log(data)

                      setInternalSalesData([...data])
                    }}
                    error={errorsObj[index]}
                    cockpitUsersQuery={cockpitUsersQuery}
                    usersQuery={usersQuery}
                    isReadOnly={isReadOnly}
                    item={item}
                    key={index}
                  />
                )
              })
            ) : getInternalSalesDetails?.isLoading ||
              getInternalSalesDetails?.isPending ? (
              <Table.Tr>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
                <Table.Td>
                  <Skeleton h={40} w={'100%'} />
                </Table.Td>
              </Table.Tr>
            ) : (
              <Table.Tr>
                <Table.Td colSpan={11} align='center'>
                  <Text c={'dimmed'}>No records found</Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
        {!['cancelled', 'auto cancelled']?.includes(activeLeg?.status)
        ? (
          <Group justify='center' mt={'md'}>
            <Button
              size='xs'
              leftSection={<IconPlus size={16} />}
              variant='light'
              onClick={addNewInternalSales}
            >
              Add New
            </Button>
          </Group>
        ) : null}
      </Box>
    )
  }
)

export default InternalOrders

import dayjs from 'dayjs'
import { create } from 'zustand'

function calculatePattern (sector) {
  let pattern = []
  if (sector.length >= 1) {
    sector.forEach((item, index) => {
      if (index === 0) {
        pattern.push(item.from, item.to)
      } else {
        pattern.push(item.to || '')
      }
    })
  }
  return pattern.join('-')
}

export const useSectorStore = create((set, get) => ({
  sector: [],
  pattern: '',
  updateSector: false,
  setPattern: p => set({ pattern: p }),
  setSector: (i, obj) => {
    const sector = get().sector
    const oldPattern = get().pattern
    if (i !== undefined) sector[i] = obj
    else sector.push(obj)

    const pattern = calculatePattern(sector)
    set({ sector, pattern, updateSector: oldPattern !== pattern })
  },
  removeSector: () => {
    const sector = get().sector
    sector.pop()
    const pattern = calculatePattern(sector)
    set({ sector, pattern, updateSector: true })
  },
  reset: () => set({ sector: [], pattern: '', updateSector: false })
}))
export const useJourneyLegInventoryStore = create((set, get) => ({
  inventory: [],
  inventoryError: false,
  setInventory: d => set({ inventory: d, inventoryError: false }),
  setInventoryError: flag => set({ inventoryError: flag }),
  setInventoryItem: obj => {
    const inventory = get().inventory
    const result = inventory.map(item => {
      if (item?.id === obj?.id) {
        return obj
      }
      return item
    })
    set({ inventory: result, inventoryError: false })
  },
  reset: () => set({ inventory: [], inventoryError: false })
}))

export const useJourneyStore = create((set, get) => ({
  journey: {
    journey_date: dayjs(new Date()).format('YYYY-MM-DD')
  },
  journeyErrors: {},
  setJourney: obj => set({ journey: { ...obj } }),
  setJourneyErrors: obj => set({ journeyErrors: { ...obj } }),
  reset: () =>
    set({
      journey: {
        journey_date: dayjs(new Date()).format('YYYY-MM-DD')
      },
      journeyErrors: {}
    })
}))

export const useJourneyLegsStore = create((set, get) => ({
  legs: [{}],
  legErrors: [],
  updateLegs: false,
  setLegs: legs => set({ legs }),
  setLegsByIndex: (i, obj) => {
    const legs = get().legs
    if (i !== undefined) {
      legs[i] = obj
    } else legs.push(obj)
    set({ legs, updateLegs: true })
  },
  setLegErrors: errList => {
    set({ legErrors: errList })
  },
  addNewLeg: () => {
    const legs = get().legs
    let depart_airport_id
    const prevLeg = legs[legs.length - 1]
    depart_airport_id = prevLeg?.arrive_airport_id

    legs.push({
      depart_airport_id,
      aircraft_id: prevLeg?.aircraft_id
    })
    set({ legs, updateLegs: true })
  },
  removeLeg: i => {
    const legs = get().legs
    legs.splice(i, 1)
    set({ legs, updateLegs: true })
  },
  reset: () => {
    set({ legs: [{}], legErrors: [], updateLegs: false })
  }
}))

export const checkNewJourneyErrors = () => {
  let hasJourneyErrors = false,
    hasLegErrors = false,
    hasInventoryError = false
  const journeyData = useJourneyStore.getState().journey
  const legsData = useJourneyLegsStore.getState().legs
  const inventory = useJourneyLegInventoryStore.getState().inventory

  const journeyErrors = {},
    legErrors = new Array(legsData.length).fill({})
  if (!journeyData.journey_date) {
    hasJourneyErrors = true
    journeyErrors.journey_date = 'Choose a valid date'
  }
  if (!journeyData.aircraft_id) {
    hasJourneyErrors = true
    journeyErrors.aircraft_id = 'Choose a valid aircraft'
  }

  legsData.forEach((leg, index) => {
    if (!leg.flight_number) {
      hasLegErrors = true
      legErrors[index].flight_number = 'Enter flight no'
    }
    if (!leg.departure_time) {
      hasLegErrors = true
      legErrors[index].departure_time = 'Choose'
    }
    if (!leg.depart_airport_id) {
      hasLegErrors = true
      legErrors[index].depart_airport_id = 'Choose'
    }
    if (!leg.arrive_airport_id) {
      hasLegErrors = true
      legErrors[index].arrive_airport_id = 'Choose'
    }
    if (!leg.senior_cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].senior_cabin_crew_id = 'Choose'
    }
    if (!leg.cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].cabin_crew_id = 'Choose'
    }
    if (!leg.senior_cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].senior_cabin_crew_id = 'Choose'
    }
  })

  if (hasJourneyErrors) {
    useJourneyStore.getState().setJourneyErrors(journeyErrors)
  }

  if (hasLegErrors) {
    useJourneyLegsStore.getState().setLegErrors(legErrors)
  }

  // --- Empty inventory can be allowed (uncomment the below snippet if empty inventory is not allowed) ------
  // const inv = inventory.filter(item => item.opening > 0);
  // if (inv.length === 0) {
  //   hasInventoryError = true;
  //   useJourneyLegInventoryStore.getState().setInventoryError(true);
  // }

  return hasJourneyErrors || hasLegErrors || hasInventoryError
}

export const checkOldJourneyErrors = ({ currentLeg }) => {
  let hasJourneyErrors = false,
    hasLegErrors = false,
    hasInventoryError = false
  const journeyData = useJourneyStore.getState().journey
  const legsData = useJourneyLegsStore.getState().legs
  const inventory = useJourneyLegInventoryStore.getState().inventory

  const journeyErrors = {},
    legErrors = new Array(legsData.length).fill({})
  if (!journeyData.journey_date) {
    hasJourneyErrors = true
    journeyErrors.journey_date = 'Choose a valid date'
  }
  if (!journeyData.aircraft_id) {
    hasJourneyErrors = true
    journeyErrors.aircraft_id = 'Choose a valid aircraft'
  }

  legsData.forEach((leg, index) => {
    if (!leg.flight_number) {
      hasLegErrors = true
      legErrors[index].flight_number = 'Enter flight no'
    }
    if (!leg.departure_time) {
      hasLegErrors = true
      legErrors[index].departure_time = 'Choose'
    }
    if (!leg.depart_airport_id) {
      hasLegErrors = true
      legErrors[index].depart_airport_id = 'Choose'
    }
    if (!leg.arrive_airport_id) {
      hasLegErrors = true
      legErrors[index].arrive_airport_id = 'Choose'
    }
    if (!leg.senior_cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].senior_cabin_crew_id = 'Choose'
    }
    if (!leg.cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].cabin_crew_id = 'Choose'
    }
    if (!leg.senior_cabin_crew_id) {
      hasLegErrors = true
      legErrors[index].senior_cabin_crew_id = 'Choose'
    }
  })

  if (hasJourneyErrors) {
    useJourneyStore.getState().setJourneyErrors(journeyErrors)
  }

  if (hasLegErrors) {
    useJourneyLegsStore.getState().setLegErrors(legErrors)
  }

  /*
  Removing the validation and allowing the zero uplift just like we allow during journey create
  if (currentLeg === 1) {
    console.log(inventory.filter(item => item.opening > 0))
    const inv = inventory.filter(item => item.opening > 0)
    if (inv.length === 0) {
      hasInventoryError = true
      useJourneyLegInventoryStore.getState().setInventoryError(true)
    }
  }
  */

  return hasJourneyErrors || hasLegErrors || hasInventoryError
}

export const getFormattedJourneyDataForInsert = () => {
  const data = useJourneyStore.getState().journey
  const legs = useJourneyLegsStore.getState().legs
  const pattern = useSectorStore.getState().pattern
  const inventory = useJourneyLegInventoryStore.getState().inventory

  const journey_leg_inventory_first_leg = []
  const journey_leg_inventory_other_legs = []
  inventory.forEach(item => {
    journey_leg_inventory_first_leg.push({
      product_id: item.id,
      opening_scc: Math.ceil(item.opening / 2),
      opening_cc: Math.floor(item.opening / 2),
      last_inv_updated_by_scc: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
      last_inv_updated_by_cc: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
    })

    journey_leg_inventory_other_legs.push({
      product_id: item.id
    })
  })

  const journey_leg = legs.map((leg, index) => {
    return {
      ...leg,
      leg_number: index + 1,
      is_last_leg: index === legs.length - 1 ? true : false,
      arrive_airport_id: Number(leg.arrive_airport_id),
      depart_airport_id: Number(leg.depart_airport_id),
      cabin_crew_id: Number(leg.cabin_crew_id),
      senior_cabin_crew_id: Number(leg.senior_cabin_crew_id),
      journey_leg_inventory:
        index === 0
          ? journey_leg_inventory_first_leg
          : journey_leg_inventory_other_legs
    }
  })

  return {
    ...data,
    sector_pattern: pattern,
    journey_status: 'active',
    journey_leg
  }
}

export const getFormattedJourneyDataForUpdate = (options = {}) => {
  const journeyData = useJourneyStore.getState().journey
  const { legs, updateLegs } = useJourneyLegsStore.getState()
  const { pattern, updateSector } = useSectorStore.getState()
  const inventory = useJourneyLegInventoryStore.getState().inventory

  const updateJourney = journeyData.updateJourney || updateSector

  const journey_leg_inventory = []
  console.log(options.currentLeg)
  if (options.currentLeg === 1) {
    inventory.forEach(item => {
      // "journey_leg_id" is mandatory for adding new items to the same leg
      let oldLegDetails = {
        journey_leg_id: legs?.[0]?.id
      }

      if (item.old?.id) {
        // ignore "journey_leg_id" if the item is already in db
        // add only the "id" field to oldLegDetails if the item is already in db
        oldLegDetails = {
          id: item.old?.id
        }
      }
      if (!item.old?.id && item.opening === 0) {
        // do nothing if the opening is 0 and not already in db
      } else {
        journey_leg_inventory.push({
          ...oldLegDetails,
          product_id: item.id,
          opening_scc: Math.ceil(item.opening / 2),
          opening_cc: Math.floor(item.opening / 2),
          last_inv_updated_by_scc: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
          last_inv_updated_by_cc: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
        })
      }
    })
  }

  let journey_leg = []
  if (updateLegs || updateJourney) {
    journey_leg = legs.map((leg, index) => {
      return {
        ...leg,
        journey_id: journeyData?.id,
        journey_date: journeyData?.journey_date,
        aircraft_id:
          Number(leg?.aircraft_id) || Number(journeyData?.aircraft_id),
        sector_pattern: pattern,
        leg_number: index + 1,
        is_last_leg: leg.is_last_leg,
        arrive_airport_id: Number(leg.arrive_airport_id),
        depart_airport_id: Number(leg.depart_airport_id),
        cabin_crew_id: Number(leg.cabin_crew_id),
        senior_cabin_crew_id: Number(leg.senior_cabin_crew_id)
      }
    })
  }

  let updateInventory = Boolean(journey_leg_inventory?.length)

  return {
    updateJourney,
    updateInventory,
    updateLegs: updateLegs || updateJourney,
    journey: {
      ...journeyData,
      sector_pattern: pattern
    },
    journey_leg,
    journey_leg_inventory
  }
}

export const clearJourneyStore = () => {
  useJourneyStore.getState().reset()
  useJourneyLegsStore.getState().reset()
  useSectorStore.getState().reset()
}

export const clearJourneyErrors = () => {
  useJourneyStore.getState().setJourneyErrors({})
  useJourneyLegsStore.getState().setLegErrors([])
}

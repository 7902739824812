import { Anchor, Badge, Image } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import dayjs from 'dayjs'

const journeyStatus = {
  open: { color: 'blue' },
  closed: { color: 'gray' },
  cancelled: { color: 'red', width: 60 },
  'partially open': { color: 'blue', width: 80 },
  'auto cancelled': { color: 'red.7', width: 100 }
}

const DocumentLink = ({ link, alt }) => {
  return (
    <Anchor href={link} target='_blank' alt={alt} title={link}>
      <Badge size='sm' variant='light' leftSection={<IconEye size={12} />}>
        View
      </Badge>
    </Anchor>
  )
}

const valueTypesMap = new Map()
valueTypesMap.set('text', (v, { moduleType }) =>
  v ? (
    typeof v === 'number' ? (
      <p
        style={{
          textAlign: moduleType === 'report' && 'right',
          width: moduleType === 'report' && 100
        }}
      >
        {v}
      </p>
    ) : (
      <p
        style={{
          textAlign:
            moduleType === 'report' && ['Yes', 'No']?.includes(v) && 'center'
        }}
      >
        {v}
      </p>
    )
  ) : (
    '' + (v || '')
  )
)
valueTypesMap.set('date', v => (v ? dayjs(v).format('DD-MMM-YY') : '-'))
valueTypesMap.set('datetime', v =>
  v ? dayjs(v).format('DD-MMM-YYYY hh:mm A') : '-'
)
// eslint-disable-next-line no-extra-boolean-cast
valueTypesMap.set('boolean', v =>
  Boolean(v) ? (
    <Badge size='sm' variant='light'>
      Yes
    </Badge>
  ) : (
    <Badge size='sm' variant='light' color='red'>
      No
    </Badge>
  )
)
valueTypesMap.set('checkbox', v =>
  Boolean(v) ? (
    <Badge size='sm' variant='light'>
      Yes
    </Badge>
  ) : (
    <Badge size='sm' variant='light' color='red'>
      No
    </Badge>
  )
)
valueTypesMap.set('time', v =>
  v ? dayjs(`2001-01-01 ${v}`).format('HH:mm') : '-'
)
valueTypesMap.set('FORM_SEARCH', v =>
  typeof v === 'string' ? v?.toUpperCase() : v || '' + (v || '')
)
valueTypesMap.set('image', v =>
  typeof v === 'string' ? (
    <Image
      src={v}
      mah={30}
      alt='Image'
      radius='sm'
      fit='contain'
      fallbackSrc='https://placehold.co/45x30?text=Img'
    />
  ) : (
    v || 'NA'
  )
)
valueTypesMap.set('document', v =>
  typeof v === 'string' ? (
    <DocumentLink link={v} alt={'GRN Document'} />
  ) : (
    v || 'NA'
  )
)

valueTypesMap.set('varchar', v => {
  if (journeyStatus[v]) {
    return (
      <Badge
        size='md'
        variant='light'
        color={journeyStatus[v]?.color}
        styles={{
          label: {
            textTransform: 'capitalize',
            width: journeyStatus[v]?.width || 50
          }
        }}
      >
        {v}
      </Badge>
    );
  } else {
    return (
      <Badge size="md" variant="light">
        {v || 'NA'}
      </Badge>
    );
  }
});

export const VALUE_TYPES = {
  text: 'text',
  date: 'date',
  amount: 'amount',
  datetime: 'datetime',
  time: 'time',
  boolean: 'boolean',
  image: 'image',
  document: 'document',
  checkbox: 'checkbox',
  varchar: 'varchar',
  // form_search: 'form_search',
}

export const changeValueFormat = (value, type = 'text', props = {}) => {
  const t = valueTypesMap.has(type) ? type : 'text'

  return valueTypesMap.get(t)(value, props)
}

import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { displayNotification } from '../../commonComponents/notifications/displayNotification'
import Body from '../../commonComponents/body/Body'
import { generateReportHeaders } from '../../utils/table.utils'
import { getReportData } from '../../services/common.service'
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Paper,
  Popover,
  PopoverDropdown,
  PopoverTarget,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactTable from '../../commonComponents/reactTable/ReactTable'
import {
  IconArrowLeft,
  IconCurrencyRupee,
  IconDownload,
  IconFilter,
  IconSearch
} from '@tabler/icons-react'
import { COLORS } from '../../constants/colors'
import { useDebouncedCallback } from '@mantine/hooks'
import { FormBuilderFields } from '../../commonComponents/formBuilder/FormBuilderFields'
import { dateMapper } from '../../utils/dataMapper'

const SummaryText = ({ item, data }) => {
  let val = data
  if (item?.type === 'currency') {
    val = val?.toLocaleString('en-IN')
  }
  return (
    <Text fw={600} size='lg'>
      {val}
    </Text>
  )
}

/**
 * Process the headers by adding summary values to the headers array.
 * @param {object} data - The data object that contains the headers and summary.
 * @returns {object} The processed headers and summary.
 */
const processHeaders = (data, state) => {
  const response = state?.report_summary.map(item => {
    const headerItem = Object.keys(data?.metadata?.summary).find(
      h => item.field === h
    )

    if (headerItem) {
      return {
        ...item,
        value: data?.metadata?.summary?.[headerItem]
      }
    }
    return null
  })

  return {
    headers: generateReportHeaders(data?.metadata?.headers, 'report'),
    ...data,
    summary: response
  }
}

const processFilter = data => {
  return data?.length
    ? data?.map(item => ({
        ...item,
        value:
          item?.interface === 'DATE'
            ? dateMapper(item?.default_value)
            : item?.default_value
      }))
    : []
}

const processFilterState = data => {
  let result = {}
  data?.length
    ? data?.map(item => {
        result[item.field] = Array.isArray(item.default_value)
          ? item.default_value?.map(i => `${i}`)
          : item.interface === 'DATE'
          ? dateMapper(item.default_value)
          : item.default_value
      })
    : {}

  return result
}

const ReportPage = () => {
  const { state = {} } = useLocation()
  const navigate = useNavigate()
  const source = state?.api
  const filters = state?.filters ? JSON.parse(state?.filters) : {}
  const reportName = state?.report_name
  const summary = useMemo(
    () => state?.report_summary?.map(d => d?.field),
    [state?.report_summary]
  )

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    search: '',
    source,
    sort: [],
    summary,
    filter: processFilter(filters)
  })
  const [searchValue, setSearchValue] = useState('')
  const [filterValue, setFilterValue] = useState({
    data: processFilterState(filters)
  })

  const changeValue = useDebouncedCallback(() => {
    setPagination({ ...pagination, page: 1, search: searchValue })
  }, 500)

  const handleChange = e => {
    setSearchValue(e)
    changeValue()
  }

  const allRecordsQuery = useQuery({
    queryKey: ['all-records', source, pagination],
    queryFn: () => getReportData(pagination),
    select: data => {
      return processHeaders(data, state)
    }
  })

  const exportDataMutation = useMutation({
    mutationKey: 'export-report-' + source,
    mutationFn: () => getReportData({ ...pagination, download: true }),
    onSuccess: res => {
      if (res?.data?.[0]?.url) {
        window.open(res?.data?.[0]?.url, '_blank')
      } else {
        displayNotification({
          message: 'File Download Failed',
          variant: 'error'
        })
      }
    },
    onError: err => {
      displayNotification({
        message: err?.message || err,
        variant: 'error'
      })
    }
  })

  /**
   * Given an array of filters and an object of filter values,
   * generates a filter expression and updates the pagination state
   * with the new filter expression and resets the page to 1.
   * @param {array} filters - An array of filter objects
   * @param {object} filterValues - An object of filter values
   */
  const generateFilterExpression = (filters, filterValues) => {
    if (!filters) return ''

    let result = []
    Object.entries(filterValues).map(item => {
      if (item[1]?.length) {
        result.push({
          ...filters.find(i => i.field === item[0]),
          value: item[1]
        })
      }
    })

    setPagination(prev => ({ ...prev, filter: result, page: 1 }))
  }

  useEffect(() => {
    if (pagination?.search) {
      setPagination(old => ({ ...old, page: 1 }))
    }
    generateFilterExpression(filters, filterValue?.data)
  }, [pagination?.search, filterValue?.data])

  useEffect(() => {
    if (filters?.filter(i => i?.default_value)?.length) {
      setFilterValue({ data: processFilterState(filters) })
    }
  }, [])

  const handleSorting = e => {
    // find the sorting data in the array from previous state
    const d = e(pagination.sort)
    setPagination(old => ({ ...old, sort: d }))
  }

  const handleExport = () => {
    exportDataMutation.mutate(pagination)
  }
  console.log(reportName)

  return (
    <Body>
      <Flex dir='row' gap={'sm'}>
        <Box mt={'md'}>
          <IconArrowLeft
            cursor={'pointer'}
            size={24}
            color='gray'
            onClick={() => navigate('/reports')}
          />
        </Box>
        <Box flex={1}>
          <Title>{state.report_name}</Title>
          <Text>{state.purpose}</Text>
        </Box>

        <Box mt={'md'}>
          <Group>
            <Popover
              position='bottom'
              withArrow
              shadow='md'
              closeOnClickOutside={false}
            >
              <PopoverTarget>
                <ActionIcon variant='outline' color={COLORS.green} size={'30'}>
                  <IconFilter strokeWidth={1.5} />
                </ActionIcon>
              </PopoverTarget>
              <PopoverDropdown>
                {filters.map((item, index) => (
                  <FormBuilderFields
                    key={index}
                    value={filterValue?.data}
                    setValue={setFilterValue}
                    item={item}
                  />
                ))}
              </PopoverDropdown>
            </Popover>
            <TextInput
              placeholder='search'
              size='xs'
              leftSection={<IconSearch size={16} />}
              value={searchValue}
              onChange={e => handleChange(e?.target.value)}
            />

            <Button
              size='xs'
              variant='outline'
              color={COLORS.green}
              rightSection={<IconDownload size={16} />}
              loading={exportDataMutation?.isLoading}
              onClick={handleExport}
            >
              Download
            </Button>
          </Group>
        </Box>
      </Flex>

      <Box mt={'md'}>
        <Box mb={'md'}>
          <Grid hidden={!allRecordsQuery?.data?.summary?.length}>
            {allRecordsQuery?.data?.summary?.map((item, index) => {
              return (
                <Grid.Col span={2} key={index}>
                  <Paper shadow='xs' p={'sm'} pos={'relative'}>
                    <SummaryText item={item} data={item?.value} />
                    <Text c={'gray.7'} size='sm'>
                      {item?.label}
                    </Text>
                    {item?.type === 'currency' && (
                      <IconCurrencyRupee
                        size={52}
                        strokeWidth={1.5}
                        color='#dcdcdc'
                        style={{ position: 'absolute', right: 4, top: 4 }}
                      />
                    )}
                  </Paper>
                </Grid.Col>
              )
            })}
          </Grid>
        </Box>
        <ReactTable
          sorting={pagination.sort}
          setSorting={handleSorting}
          columnData={allRecordsQuery?.data?.headers}
          loading={allRecordsQuery?.isFetching}
          onRowClick={e => null}
          totalNoOfPages={allRecordsQuery?.data?.metadata?.total_number_of_page}
          totalNoOfRecords={allRecordsQuery?.data?.metadata?.records}
          rowData={allRecordsQuery?.data?.data}
          page={pagination?.page}
          pageSize={pagination?.per_page}
          setPage={page => setPagination({ ...pagination, page })}
          setPageSize={pageSize =>
            setPagination({ ...pagination, per_page: pageSize })
          }
          reportName={reportName}
        />
      </Box>
    </Body>
  )
}

export default ReportPage
